"use client";

import { bindActionCreators } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { errorActions } from "@/store/error/error.slice";
import { authActions } from "@/store/auth/auth.slice";
import { contentActions } from "@/store/content/content.slice";
import { companiesActions } from "@/store/companies/companies.slice";
import { filtersActions } from "@/store/filters/filters.slice";
import { fundsActions } from "@/store/funds/funds.slice";
import { fundraisingActions } from "@/store/fundraising/fundraising.slice";
import { searchActions } from "@/store/search/search.slice";
import { peopleActions } from "@/store/people/people.slice";
import { tagsActions } from "@/store/tags/tags.slice";
import { profileActions } from "@/store/profile/profile.slice";
import { acctFundsActions } from "@/store/acctFunds/acctFunds.slice";
import { notificationsActions } from "@/store/notifications/notifications.slice";
import { companiesListAction } from "@/store/companiesList/companiesList.slice";
import { viewsActions } from "@/store/views/views.slice";
import { newCompanieListActions } from "@/store/newCompaniesList/newCompaniesList.slice";
import { newViewActions } from "@/store/newView/newView.slice";
import { newListActions } from "@/store/newLists/newLists.slice";

const rootActions = {
  ...errorActions,
  ...authActions,
  ...profileActions,
  ...contentActions,
  ...companiesActions,
  ...companiesListAction,
  ...filtersActions,
  ...fundsActions,
  ...fundraisingActions,
  ...searchActions,
  ...peopleActions,
  ...tagsActions,
  ...acctFundsActions,
  ...notificationsActions,
  ...viewsActions,
  ...newCompanieListActions,
  ...newViewActions,
  ...newListActions,
} as const;

// Create a type for all actions
type RootActions = typeof rootActions;

export const useActions = () => {
  const dispatch = useDispatch();
  
  return useMemo(() => {
    // During SSR, return a proxy that logs when actions are called
    if (typeof window === 'undefined') {
      return new Proxy({} as RootActions, {
        get: (target, prop) => {
          return (...args: any[]) => {
            console.warn(`Action ${String(prop)} called during SSR`);
            return { type: String(prop), payload: args[0] };
          };
        },
      });
    }
    
    return bindActionCreators(rootActions, dispatch);
  }, [dispatch]);
};
