// src/services/apiService/axiosService.ts

import axios from "axios";
import { ENVIRONMENT_CONFIGS } from "../../../env_config";

const currentEnv = process.env.NEXT_ENVIRONMENT || "development";
console.info("API URL:", ENVIRONMENT_CONFIGS[currentEnv].apiUrl);
console.info("Environment:", process.env.NEXT_ENVIRONMENT);

export const axiosService = axios.create({
  baseURL: ENVIRONMENT_CONFIGS[currentEnv].apiUrl,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

export const axiosAuthService = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SB_AUTH_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

axiosAuthService.interceptors.request.use(
  (config) => {
    console.debug(
      `Sending ${config.method.toUpperCase()} request to Next Api: ${config.url}`,
    );
    return config;
  },
  (error) => {
    console.error("Request error:", error);
    return Promise.reject(error);
  },
);

axiosService.interceptors.request.use(
  (config) => {
    console.debug(
      `Sending ${config.method.toUpperCase()} request to: ${config.url}`,
    );
    return config;
  },
  (error) => {
    console.error("Request error:", error);
    return Promise.reject(error);
  },
);

axiosService.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorInfo = {
      url: error.config?.url,
      method: error.config?.method,
      status: error.response?.status,
      message: error.message,
      data: error.response?.data,
    };
    console.error("API Request Failed:", errorInfo);

    const customError = error as Error & { info?: any };
    customError.info = errorInfo;
    return Promise.reject(customError);
  },
);
