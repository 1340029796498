// src/utils/env/googleSecretRetrieval.js
// This file contains functions for retrieving Google Cloud Secrets

const { SecretManagerServiceClient } = require("@google-cloud/secret-manager");
const { logger } = require("../../utils/logger");

let client;

function getSecretManagerClient() {
  if (!client) {
    client = new SecretManagerServiceClient();
  }
  return client;
}

async function getSecret(secretName) {
  const client = getSecretManagerClient();
  try {
    const projectId = await getGCPProjectId();
    const name = `projects/${projectId}/secrets/${secretName}/versions/latest`;
    const [version] = await client.accessSecretVersion({ name });
    const payload = version.payload.data.toString("utf8");
    return payload;
  } catch (error) {
    logger.error(
      `[ERROR] at getSecret: Failed to retrieve secret ${secretName}:`,
      error,
    );
    throw error;
  }
}

async function getGCPProjectId() {
  const client = getSecretManagerClient();
  try {
    const projectId = await client.getProjectId();
    return projectId;
  } catch (error) {
    if (process.env.GOOGLE_CLOUD_PROJECT) {
      return process.env.GOOGLE_CLOUD_PROJECT;
    }
    logger.error(
      "[ERROR] at getGCPProjectId: Error getting GCP project ID:",
      error,
    );
    throw error;
  }
}

module.exports = {
  getSecret,
};
