'use client';

import { Box, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import BellIcon from '@/assets/svg/bell_light.svg';
import NotificationsList from './NotificationsList';
import { useSelector } from 'react-redux';
import { getUnreadNotificationsData } from '@/store/notifications/notifications.selectors';
import { usePathname } from 'next/navigation';

// import { getUserProfileInfo } from '@/store/profile/profile.selectors';

export const NotificationMenu = () => {
  const pathname = usePathname();
  const unreadNotifications = useSelector(getUnreadNotificationsData());

  return (
    <Menu>
      <MenuButton>
        <Box position={'relative'}>
          <BellIcon />
          {unreadNotifications?.length ? (
            <Box
              position={'absolute'}
              h={'7px'}
              w={'7px'}
              p={'2px'}
              top={'2px'}
              right={'2px'}
            >
              <Box borderRadius={'50%'} bg={'red'} h={'5px'} w={'5px'}></Box>
            </Box>
          ) : null}
        </Box>
      </MenuButton>
      {pathname !== '/notifications' ? (
        <MenuList
          display={'flex'}
          flexDir={'column'}
          gap={'12px'}
          bg={'dashboardBackground'}
          border={'none'}
          borderTopLeftRadius={'0px'}
          borderTopRightRadius={'0px'}
          position='absolute'
          top='11px'
          left={'-398px'}
          zIndex={1100}
          overflowY={'auto'}
          overflowX={'hidden'}
          w={'507px'}
          h={'352px'}
          p={'20px'}
          px={'20px'}
          overflow={'auto'}
          className='notifications-list'
        >
          <NotificationsList
            {...{
              isHeaderMenu: true,
            }}
          />
        </MenuList>
      ) : null}
    </Menu>
  );
};
