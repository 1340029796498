import { Button, Flex, Text } from '@chakra-ui/react';

const FormButton = ({ children, button, title, id, handler }:{children?:any, button?:any, title?:any, id?:any, handler?:any}) => {
  return (
    <Button
      id={id}
      variant={'unstyled'}
      w={'100%'}
      h={'36px'}
      py={'11px'}
      px={'24px'}
      fontSize={'14px'}
      fontWeight={700}
      lineHeight={'normal'}
      color={'white'}
      borderRadius={'4px'}
      bg={'mainBlue'}
      _hover={{ bg: 'blue' }}
      _disabled={{ bg: 'disabledBg' }}
      onClick={handler}
      {...button}
    >
      {title ? <Text mt={'2px'}>{title}</Text> : null}
      {children ? (
        <Flex mt={'2px'} w={'100%'} justifyContent={'center'} h={'100%'}>
          {children}
        </Flex>
      ) : null}
    </Button>
  );
};

export default FormButton;
