// src/components/research/components/fundraisingRounds/components/FundraisingRoundsElement.jsx

/**
 * Renders a fundraising round element for a company
 * @param {Object} props - Component props
 * @param {Object} props.data - Company data including fundraising details
 * @returns {JSX.Element} Fundraising round element
 */

import { CustomElement } from "@/components/ui/box/CustomElement";
import DefaultImage from "@/components/ui/DefaultImage";
import {
  h_400_12_100_48_trim,
  h_400_14_normal_28,
  h_700_14_100_28,
  h_700_16_100_32,
} from "@/styles/fontStyles";
import { addressSelector } from "@/utils/addressSelector";
import { formatNumber } from "@/utils/formatNumber";
import { imgSelector } from "@/utils/imgSelector";
import { maskFundraisingRoundsBlock } from "@/utils/masksForResearchPage";
import { Flex, Heading, Text } from "@chakra-ui/react";
import Link from "next/link";

export const FundraisingRoundsElement = ({ data }) => {
  const dataCompany = { ...data?.resEntity };
  const address = addressSelector({ data: dataCompany?.addresses });
  return (
    <Link href={`/companies/${dataCompany?.slug}`} alt={dataCompany?.nameBrand}>
      <CustomElement
        variant={"whiteBtn"}
        customStyle={{
          w: "100%",
          maxW: { base: "calc(100vw - 40px)" },
          h: "fit-content",
          p: "10px 20px 20px 20px",
          borderRadius: "10px",
          borderBottom: "1px solid",
          borderColor: "menuSearchColor",
          _hover: {
            bgColor: "menuSearchColor",
          },
        }}
      >
        <Flex
          flexDir={{ base: "column", md: "row" }}
          align={{ base: "unset", md: "center" }}
          justify={{ base: "unset", md: "space-between" }}
          w={"full"}
          gap={"16px"}
        >
          <Flex
            flexDir={"column"}
            gap={"8px"}
            minW={"128px"}
            w={"full"}
            maxW={{ base: "100%", md: "50%" }}
          >
            <Flex gap={"8px"} align={"center"}>
              <DefaultImage
                src={imgSelector({ data: dataCompany })}
                options={{
                  alt: "company name",
                  sx: {
                    w: "24px",
                    h: "24px",
                    maxW: "24px",
                    minW: "24px",
                    borderRadius: "2px",
                    backgroundColor: "#FBFBFB",
                    boxShadow: "0px 0px 14px 0px rgba(114, 114, 114, 0.25)",
                    objectFit: "cover",
                    p: "2px",
                  },
                }}
              />
              <Heading
                {...h_700_16_100_32}
                color={"mainBlue"}
                pt={"2px"}
                pb={"1px"}
                isTruncated
              >
                {dataCompany?.nameBrand}
              </Heading>
            </Flex>
            {address && address !== "—" ? (
              <Text
                {...h_400_14_normal_28}
                color={"black"}
                pt={"3px"}
                textAlign={"left"}
                w="95%"
                maxW={"95%"}
                isTruncated
              >
                {address}
              </Text>
            ) : null}
          </Flex>
          <Flex
            w={{ base: "100%", md: "100%" }}
            maxW={{ base: "100%", md: "50%" }}
            gap={"10px"}
          >
            {maskFundraisingRoundsBlock.slice(3).map((el) => {
              return (
                <Flex
                  flexDir={"column"}
                  key={`fundraising-${el.field}`}
                  textAlign={"left"}
                  gap={"4px"}
                  flexBasis={"33%"}
                  maxW={"33%"}
                >
                  <Text {...h_400_12_100_48_trim} color={"gray"}>
                    {el?.title}
                  </Text>
                  <Text
                    {...h_700_14_100_28}
                    color={"mainBlue"}
                    pt={"2px"}
                    isTruncated
                  >
                    {el?.title === "Raised"
                      ? formatNumber(data[el?.field])
                      : data?.[el?.field] 
                        ? String(data[el?.field])
                        : "—"}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      </CustomElement>
    </Link>
  );
};
