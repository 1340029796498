import SearchSvg from "@/assets/svg/search.svg";
import { CustomElement } from "@/components/ui/box/CustomElement";
import { MainWrapper } from "@/components/ui/box/MainWrapper";
import { r_400_54_normal } from "@/styles/fontStyles";
import { buttonsSecondLineAfterSearch } from "@/utils/masksForResearchPage";
import { Flex, Icon, Input, Text, useMediaQuery } from "@chakra-ui/react";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useCallback, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import SendIcon from "@/assets/svg/send-icon.svg";

export const TitleBlock = ({ tagList }) => {
  const getRandomTags = useCallback((tagList, num) => {
    return tagList
      .sort(() => Math.random() - 0.5)
      .slice(0, Math.min(num, tagList.length));
  }, []);

  const router = useRouter();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      search: "",
    },
  });

  const onSubmitHandler = ({ search }) => {
    search && router.push(`/search?search=${search}`);
  };

  const limitedTags = useMemo(() => {
    if (tagList && tagList.length > 4) {
      return getRandomTags(tagList, 4);
    }
    return tagList;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagList]);

  return (
    <MainWrapper
      customStyleOuter={{
        px: { base: "24px", md: "40px" },
      }}
    >
      <Text
        {...{
          as: "h1",
          textAlign: "center",
          ...r_400_54_normal,
          fontSize: { base: "54px", md: "70px", lg: "80px" },
          lineHeight: "120%",
          letterSpacing: "0.2px",
          color: "#22262A",
          mt: { base: "30px", md: "64px", desk: "60px" },
          maxW: "718px",
          alignSelf: "center",
        }}
      >
        Research Startups
      </Text>
      <Flex
        flexDir={"column"}
        mt={{ base: "48px" }}
        mb={{ base: "40px", md: "76px" }}
        align={"center"}
      >
        <Flex
          as={"form"}
          bg={"dashboardBackground"}
          w={"100%"}
          maxW={{ base: "100%", md: "660px" }}
          h={"50px"}
          columnGap={"6px"}
          align={"center"}
          px={"12px"}
          borderRadius={"10px"}
          borderWidth={"1px"}
          borderColor={"borderGray"}
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <Icon
            as={SearchSvg}
            color={"gray"}
            bgColor={"transparent"}
            _hover={{ bgColor: "transparent", color: "mainBlue" }}
            _active={{ bgColor: "transparent" }}
          />
          <Controller
            name="search"
            control={control}
            render={({ field }) => (
              <Input
                variant={"unstyled"}
                placeholder="Search Companies, Funds, People, News"
                fontFamily={"Roboto"}
                fontWeight={"500"}
                _placeholder={{
                  pt: "4px",
                  maxW: { base: "240px", base1: "100%" },
                  isTruncated: true,
                }}
                {...field}
              />
            )}
          />
          <Icon
            as={SendIcon}
            color={"grey"}
            cursor={"pointer"}
            onClick={handleSubmit(onSubmitHandler)}
          />
        </Flex>
        <FirstLineBtn
          {...{
            data: limitedTags,
          }}
        />
        <SecondLineBtn
          {...{
            data: buttonsSecondLineAfterSearch,
          }}
        />
      </Flex>
    </MainWrapper>
  );
};

export function FirstLineBtn({ data }) {
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  return (
    <Flex gap={"12px"} mt={"12px"} mb={"24px"}>
      {data &&
        data.length > 0 &&
        [...(isMobile ? data.slice(0, 2) : data)].map((item) => (
          <Link key={item.slug} href={`/companies/tags/${item.slug}`}>
            <CustomElement title={item.name} variant="whiteBtn" />
          </Link>
        ))}
    </Flex>
  );
  // i;
}

export function SecondLineBtn({ data }) {
  return (
    <Flex gap={{ base: "4px", md: "14px" }}>
      {data.map((el) => (
        <Link href={el.href} key={el.href}>
          <CustomElement
            title={el.title || "Explore Companies"}
            variant={el.variantBtn}
            customStyle={{
              whiteSpace: "nowrap",
              h: "46px",
            }}
          />
        </Link>
      ))}
    </Flex>
  );
}
