import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentNavLink: null,
  current_Profile_Company_Id: null,
  current_Profile_Fund_Id: null,
  searchResults: "",
  companyDetailsTabIndex: 0,
  companyForCompare: [],
  sortedParams: { orderBy: "updatedAt", direction: "DESC" },
  stateSortedParams: { orderBy: "updatedAt", direction: "DESC" },
  growingFilters: { arrayFilters: {}, text: undefined },
  openCard: "Diversification",
  isLoading: false,
  investingFunds: [],
  selectedCardAnalyze: null,
  selectedCardAnalyze2: null,
  defaultCardAnalyze: null,
  ownershipList: [],
  ongoingInvestingFunds: [],
  investChart: false,
  withdrawInvestingFunds: [],
  oneNewInCompany: null,
  //-filter-----------
  isOpenFilterBlock: false,
  currentFilterList: [],
  //------------------
  tempUser: null,
};

const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    setCurrentNavLink: (state, { payload }) => {
      state.currentNavLink = payload;
    },
    setProfile_Company_id: (state, { payload }) => {
      state.current_Profile_Company_Id = payload;
    },
    setSearchResults: (state, { payload }) => {
      state.searchResults = payload;
    },
    setProfile_Fund_id: (state, { payload }) => {
      state.current_Profile_Fund_Id = payload;
    },
    setCompanyDetailsTabIndex: (state, { payload }) => {
      state.companyDetailsTabIndex = payload;
    },
    setSelectedCompanyForCompare: (state, { payload }) => {
      state.companyForCompare = payload;
    },
    clearCompanyForCompare: (state) => {
      state.companyForCompare = [];
    },
    setSortedParams: (state, { payload }) => {
      state.sortedParams = { ...payload };
    },
    setStateSortedParams: (state, { payload }) => {
      state.stateSortedParams = { ...payload };
    },
    resetSortedParams: (state) => {
      state.sortedParams = { orderBy: "updatedAt", direction: "DESC" };
    },
    setGrowingFilters: (state, { payload }) => {
      state.growingFilters = { ...state.growingFilters, ...payload };
    },
    setGrowingFiltersText: (state, { payload }) => {
      state.growingFilters = {
        ...state.growingFilters,
        arrayFilters: { ...state?.growingFilters?.arrayFilters },
        text: payload,
      };
    },
    resetGrowingFilters: (state) => {
      state.growingFilters = { arrayFilters: {}, text: "" };
    },
    resetGrowingFiltersText: (state) => {
      state.growingFilters = { ...state.growingFilters, text: "" };
    },
    setOpenCard: (state, { payload }) => {
      state.openCard = payload;
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    addInvestingFunds: (state, { payload }) => {
      state.investingFunds = [...state.investingFunds, payload];
    },
    deleteInvestingFund: (state, { payload }) => {
      state.investingFunds = state.investingFunds.filter(
        (fund) => fund.id !== payload
      );
    },
    setSelectedCardAnalyzeFund: (state, { payload }) => {
      state.selectedCardAnalyze = payload;
    },
    setSelectedCardAnalyzeFund2: (state, { payload }) => {
      state.selectedCardAnalyze2 = payload;
    },
    setDefaultCardAnalyzeFund: (state, { payload }) => {
      state.defaultCardAnalyze = payload;
    },
    addOwnershipList: (state, { payload }) => {
      state.ownershipList = [...state.ownershipList, payload];
    },
    deleteOwnership: (state, { payload }) => {
      state.ownershipList = state.ownershipList.filter(
        (owner) => owner.id !== payload
      );
    },
    addOngoingInvestingFund: (state, { payload }) => {
      state.ongoingInvestingFunds = [...state.ongoingInvestingFunds, payload];
    },
    deleteOngoingInvestingFund: (state, { payload }) => {
      state.ongoingInvestingFunds = state.ongoingInvestingFunds.filter(
        (fund) => fund.id !== payload
      );
    },
    setInvestChart: (state, { payload }) => {
      state.investChart = payload;
    },
    addWithdrawInvestingFunds: (state, { payload }) => {
      state.withdrawInvestingFunds = [...state.withdrawInvestingFunds, payload];
    },
    deleteWithdrawInvestingFunds: (state, { payload }) => {
      state.withdrawInvestingFunds = state.withdrawInvestingFunds.filter(
        (fund) => fund.id !== payload
      );
    },
    setOneNewInCompany: (state, { payload }) => {
      state.oneNewInCompany = payload;
    },
    setIsOpenFilterBlock: (state, { payload }) => {
      state.isOpenFilterBlock = payload;
    },
    setCurrentFilterList: (state, { payload }) => {
      state.currentFilterList = payload;
    },
    setTempUser: (state, { payload }) => {
      state.tempUser = payload;
    },
  },
});
export const contentActions = contentSlice.actions;

export default contentSlice;
