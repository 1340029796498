"use client";

import TooltipDefault from "@/components/ui/tooltip/TooltipDefault";
import {
  h_400_12_135_48,
  h_700_14_120_28,
  h_700_16_100_32,
} from "@/styles/fontStyles";
import { Avatar, Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import DotMenu from "./DotMenu";
import {
  useDeleteNotificationMutation,
  useMarkReadNotificationMutation,
} from "@/services/notifications.service";
import Link from "next/link";
import env_config from "env_config";

const NotificationCard = ({ itemEl }) => {
  const iconUrl = env_config.apiImageHostName;

  const [hover, setHover] = useState(false);
  const [markReadNotification] = useMarkReadNotificationMutation();
  const [deleteNotification] = useDeleteNotificationMutation();

  const markAsReadHandler = () => {
    markReadNotification(itemEl?.id);
  };
  const deleteHandler = () => {
    deleteNotification(itemEl?.id);
  };

  const list = [
    {
      id: 1,
      title: itemEl?.isRead ? "Mark as unread" : "Mark as read",
      handler: markAsReadHandler,
    },
    { id: 2, title: "Delete", handler: deleteHandler },
  ];

  return (
    <Flex
      p={"8px 10px"}
      w={"100%"}
      h={"57px"}
      borderRadius={"4px"}
      border={"1px solid grey"}
      align={"center"}
      justify={"space-between"}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => setHover(!hover)}
    >
      <Flex align={"center"} gap={"12px"}>
        <Avatar
          src={
            itemEl?.title?.includes("aVenture")
              ? "/noti-logo.svg"
              : `${iconUrl}${itemEl?.logo}`
          }
          name={itemEl?.title}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          pt={itemEl?.logo ? "6px" : undefined}
          w={"40px"}
          objectFit={"contain"}
          h={"40px"}
          filter={itemEl?.isRead ? "grayscale(100%)" : undefined}
          opacity={itemEl?.isRead ? "0.8" : undefined}
          sx={{
            img: {
              objectFit: "contain",
              bg: "white",
            },
          }}
        />
        <Flex flexDir={"column"} gap={"8px"}>
          <Text
            fontSize={{ base: "12px", md: "14px" }}
            fontWeight={{ base: "700" }}
            lineHeight={{ base: "1", md: "1.2" }}
            letterSpacing={{ base: "0.24px", md: "0.28px" }}
            color={itemEl?.isRead ? "grey" : "black"}
          >
            {itemEl.title}
          </Text>
          <Text {...h_400_12_135_48} color={itemEl?.isRead ? "grey" : "black"}>
            {new Date(itemEl.createdAt).toLocaleDateString("en-eu", {
              day: "numeric",
              month: "short",
              year: "numeric",
            })}
          </Text>
        </Flex>
      </Flex>
      <Flex flexDir={"column"} gap={"8px"} alignItems={"flex-end"}>
        {!hover ? (
          <Text
            fontSize={{ base: "12px", md: "14px" }}
            fontWeight={{ base: "700" }}
            lineHeight={{ base: "1", md: "1.2" }}
            letterSpacing={{ base: "0.24px", md: "0.28px" }}
            textTransform={"lowercase"}
            _firstLetter={{ textTransform: "uppercase" }}
            color={
              itemEl?.isRead
                ? "grey"
                : itemEl?.message === "View"
                ? "blue"
                : "black"
            }
          >
            {itemEl.nameCategory}
          </Text>
        ) : (
          <DotMenu {...{ list }} />
        )}
        {itemEl?.message?.length < 25 ? (
          <TooltipDefault
            label={itemEl?.message?.length > 25 && itemEl?.message}
            hasArrow
          >
            <Link
              href={itemEl?.url || {}}
              alt={itemEl?.title}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Text
                sx={
                  itemEl.message === "Invested" ||
                  itemEl.message === "Withdrawn"
                    ? h_700_16_100_32
                    : itemEl?.message === "View"
                    ? h_700_14_120_28
                    : h_400_12_135_48
                }
                color={
                  itemEl.message === "Invested"
                    ? "green"
                    : itemEl?.isRead
                    ? "grey"
                    : itemEl?.message === "View"
                    ? "blue"
                    : "black"
                }
                isTruncated
              >
                {itemEl.message}
              </Text>
            </Link>
          </TooltipDefault>
        ) : null}
      </Flex>
    </Flex>
  );
};

export default NotificationCard;
