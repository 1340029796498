import {
  h_400_12_100_48_trim,
  h_400_14_135_28,
  h_700_14_100,
  h_700_16_100_32,
} from "@/styles/fontStyles";
import { addressSelector } from "@/utils/addressSelector";
import { formatNumber } from "@/utils/formatNumber";
import { imgSelector } from "@/utils/imgSelector";
import { Flex, Text, Box, Heading, Tooltip } from "@chakra-ui/react";
import Link from "next/link";
import DefaultImage from "../DefaultImage";

function SmallCard({ item, textSection }) {
  const cardStyle = {
    width: "308px",
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    transition: "box-shadow 0.3s ease",
  };

  const imgStyle = {
    w: "24px",
    h: "24px",
    backgroundColor: "white",
    border: "2px solid",
    borderColor: "white",
    borderRadius: "2px",
    boxShadow: "0px 0px 14px 0px rgba(114, 114, 114, 0.25)",
    alignItems: "center",
    justifyContent: "center",
    objectFit: "cover",
  };

  const {
    slug,
    nameBrand,

    dateYearEstablished,
  } = item;
  const address = addressSelector({ data: item?.addresses });

  return (
    <Link href={`/companies/${slug ?? ""}`} alt={nameBrand}>
      <Box
        height="100%"
        sx={cardStyle}
        cursor="pointer"
        _hover={{
          boxShadow: "0px 0px 14px 0px rgba(114, 114, 114, 0.25)",
        }}
      >
        <Tooltip label={nameBrand} placement="top">
          <Flex w="100%" align="center" justify="left" mb="8px">
            <DefaultImage
              src={imgSelector({ data: item })}
              options={{
                alt: nameBrand,
                width: "24px",
                sx: imgStyle,
              }}
            />
            <Heading
              as="h5"
              {...h_700_16_100_32}
              ml="8px"
              className="truncate-text-smallCards"
            >
              {nameBrand}
            </Heading>
          </Flex>
        </Tooltip>
        <Text {...h_400_14_135_28}>{address}</Text>
        <Flex justifyContent="space-between" mt="16px" gap={"4px"}>
          <Box>
            <Text mb="4px" color="grey" {...h_400_12_100_48_trim}>
              {textSection[0]}
            </Text>
            <Text {...h_700_14_100}>{dateYearEstablished || "—"}</Text>
          </Box>
          <Box maxW={"130px"}>
            <Text mb="4px" color="grey" {...h_400_12_100_48_trim}>
              {textSection[1]}
            </Text>
            <Text {...h_700_14_100} whiteSpace={"nowrap"} isTruncated>
              {item?.stage || "—"}
            </Text>
          </Box>
          <Box>
            <Text mb="4px" color="grey" {...h_400_12_100_48_trim}>
              {textSection[2]}
            </Text>
            <Text {...h_700_14_100}>
              {formatNumber(item?.totalRaised) || "—"}
            </Text>
          </Box>
        </Flex>
      </Box>
    </Link>
  );
}

export default SmallCard;
