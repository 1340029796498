// src/services/newCompaniesList.service.ts

import { newCompanieListActions } from "@/store/newCompaniesList/newCompaniesList.slice";
import { aventureApi } from "./apiService/aventureApi";
import { axiosService } from "./apiService/axiosService";
import { loadingError } from "./error.service";
import { newListActions } from "@/store/newLists/newLists.slice";

export const newCompanieListApi = aventureApi.injectEndpoints({
  endpoints: (builder) => ({
    companiesList: builder.mutation({
      queryFn: async (options, { dispatch }) => {
        try {
          const { data } = await axiosService.post(
            "/res/entity/filters/companies",
            options?.params
          );

          dispatch(
            newCompanieListActions.setNewCompanies({
              data: data?.data,
              isCards: options?.isCards,
            })
          );
          dispatch(
            newListActions.setListParams({
              page: data?.page,
              pages: data?.pages,
              total: data?.total,
            })
          );

          return { data };
        } catch (error) {
          dispatch(
            loadingError(
              error.response?.data?.message || error?.message || error
            )
          );
          return {
            error: error.response?.data?.message || error?.message || error,
          };
        }
      },
      invalidatesTags: ["companiesList"],
    }),
    companiesByList: builder.mutation({
      queryFn: async ({ params, key }, { dispatch }) => {
        try {
          const { data } = await axiosService.post(`app/list/${key}`, params);

          dispatch(
            newCompanieListActions.setNewCompanies({ data: data?.data })
          );
          dispatch(
            newListActions.setListParams({
              page: data?.page,
              pages: data?.pages,
              total: data?.total,
            })
          );

          return { data };
        } catch (error) {
          dispatch(
            loadingError(
              error.response?.data?.message || error?.message || error
            )
          );
          return {
            error: error.response?.data?.message || error?.message || error,
          };
        }
      },
      invalidatesTags: ["companiesList"],
    }),
  }),
  overrideExisting: true,
});

export const { useCompaniesListMutation, useCompaniesByListMutation } =
  newCompanieListApi;

export default newCompanieListApi;