"use client";

import {
  menuListProfile,
  menuListResearch,
} from "@/assets/data/menuList/menuList";
import ArrowRightIcon from "@/assets/svg/arrow_right.svg";
import SearchSvg from "@/assets/svg/search.svg";
import SearchCompanies from "@/components/companies/companiesList/newCompaniesList/SearchCompanies";
import { NotificationMenu } from "@/components/notification/NotificationMenu";
import { useActions } from "@/hooks/useActions";
import { useBreakPoints } from "@/hooks/useBreakPoints";
import {
  useLazyGetNotificationsQuery,
  useLazyGetUnreadNotificationsQuery,
} from "@/services/notifications.service";
import { useLazyGetUserProfileQuery } from "@/services/profile.service";
import { useLazyGetSearchDataQuery } from "@/services/search.service";
import { getUserIsVerify } from "@/store/auth/auth.selectors";
import newCompaniesListSelectors from "@/store/newCompaniesList/newCompaniesList.selectors";
import { getUserProfileInfo } from "@/store/profile/profile.selectors";
import { h_700_16_100_32 } from "@/styles/fontStyles";
import { Flex, Text } from "@chakra-ui/react";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { useSelector } from "react-redux";
import AuthInput from "../auth/AuthInput";
import ButtonDefault from '../buttons/DefaultBtn';
import { Dropdown } from "../dropdown/Dropdown";
export const SubHeader = ({ userIsVerify }) => {
  const router = useRouter();
  const pathName = usePathname();
  const { setUserSignUpInfo } = useActions();
  const { isMobile } = useBreakPoints();
  const profile = useSelector(getUserProfileInfo());
  const [searchData, setSearchData] = useState("");
  const [getUserProfile] = useLazyGetUserProfileQuery();
  const [getNotifications] = useLazyGetNotificationsQuery();
  const [getUnreadNotifications] = useLazyGetUnreadNotificationsQuery();
  const [getSearchData] = useLazyGetSearchDataQuery();
  const isLogIn = useSelector(getUserIsVerify());
  const cookies = new Cookies();
  const isLoggedIn = cookies.get("isLoggedIn", {
    path: "/",
  });
  const isList = pathName?.endsWith("/companies");
  const isCards = useSelector(newCompaniesListSelectors.getIsCompaniesCards());

  const handlerKeyDown = (e) => {
    if (e.key === "Enter" && e.target.value?.trim() !== "") {
      getSearchData({ search: e.target.value });
      router.push(`/search?search=${e.target.value}`);
    }
  };

  const onClickIconLeft = () => {
    if (searchData?.trim() !== "") {
      getSearchData({ search: searchData });
      router.push(`/search?search=${searchData}`);
    }
  };

  const clearUserInfoHandler = () => {
    setUserSignUpInfo(null);
  };

  useEffect(() => {
    setSearchData("");
  }, [pathName]);

  useEffect(() => {
    const profile = setTimeout(() => {
      (userIsVerify || isLoggedIn) &&
        (getUserProfile(), getNotifications(), getUnreadNotifications());
    }, 10);

    return () => clearTimeout(profile);
  }, [
    userIsVerify,
    isLoggedIn,
    getUserProfile,
    getNotifications,
    getUnreadNotifications,
  ]);

  //----------------------------------------------------------------
  const [flag, setFlag] = useState(0);

  useEffect(() => {
    let timeOut;
    if (flag) {
      timeOut = setTimeout(() => {
        getNotifications();
        getUnreadNotifications();
        setFlag(0);
      }, 50);
      return () => clearTimeout(timeOut);
    }
  }, [flag, getNotifications, getUnreadNotifications]);

  const isDev =
    process.env.NEXT_ENVIRONMENT === "local" ||
    process.env.NEXT_ENVIRONMENT === "development" ||
    process.env.NEXT_ENVIRONMENT === "stage";

  return (
    <Flex align={"center"} justify={"space-between"} w={"100%"} zIndex={1000}>
      <Flex
        align={pathName !== "/" ? "center" : "left"}
        gap={"12px"}
        mt={"4px"}
      >
        {pathName !== "/" ? (
          <Link href={"/"} alt={"Home"}>
            <Text
              {...h_700_16_100_32}
              color={"black"}
              borderBottom={"2px solid transparent"}
            >
              Home
            </Text>
          </Link>
        ) : (
          <></>
        )}
        <Dropdown
          textColor={"black"}
          titleButton={"Research"}
          menuList={menuListResearch({ isDev })}
          isSubMenu={true}
        />
      </Flex>
      {isList && !isCards ? <SearchCompanies /> : null}
      <Flex align={"center"} gap={"12px"}>
        {userIsVerify || isLogIn ? (
          <Flex align={"center"} gap={"12px"}>
            {pathName !== "/" &&
              !pathName?.includes("/search") &&
              !isMobile &&
              !isList && (
                <AuthInput
                  {...{
                    icon: <SearchSvg />,
                    iconLeftStyle: { left: "6px" },
                    input: {
                      h: "34px",
                      ps: "28px",
                      fontSize: "14px",
                      borderColor: "borderColor",
                      placeholder: "Search...",
                      _placeholder: { color: "gray" },
                      _focus: {
                        boxShadow: "none",
                      },
                      value: searchData || "",
                      onChange: (e) => setSearchData(e.target.value),
                      onKeyDown: (e) => handlerKeyDown(e),
                    },
                    iconHandler: onClickIconLeft,
                  }}
                />
              )}
            <NotificationMenu />
            <Dropdown
              isProfile={true}
              src={profile?.userPhotoUpload}
              textColor={"black"}
              userName={profile?.userFullNameId}
              menuList={menuListProfile}
              left={"-125px"}
              minW={"180px"}
            />
          </Flex>
        ) : (
          <Flex align={"center"} gap={"8px"}>
            {pathName !== "/" &&
              !pathName?.includes("/search") &&
              !isMobile &&
              !isList && (
                <AuthInput
                  {...{
                    icon: <SearchSvg />,
                    iconLeftStyle: { left: "6px" },
                    input: {
                      h: "26px",
                      ps: "24px",
                      fontSize: "14px",
                      borderColor: "borderColor",
                      placeholder: "Search...",
                      _placeholder: { color: "gray", top: "4px" },
                      _focus: {
                        boxShadow: "none",
                      },
                      value: searchData || "",
                      onChange: (e) => setSearchData(e.target.value),
                      onKeyDown: (e) => handlerKeyDown(e),
                    },
                    iconHandler: onClickIconLeft,
                  }}
                />
              )}

            <Link href={"/sign-in"} alt={"Sign-In"}>
              <ButtonDefault
                title={"Login"}
                rightIcon={<ArrowRightIcon />}
                color={"mainBlue"}
                hoverColor={"blue"}
                hoverBorderColor={"blue"}
                customStyles={{ borderRadius: "4px", p: "8px 13px 8px 18px" }}
              />
            </Link>
            <Link
              href={"/sign-up"}
              alt={"Sign-Up"}
              onClick={clearUserInfoHandler}
            >
              <ButtonDefault
                backgroundColor={"mainBlue"}
                title={"Create Free Account"}
                hoverBgColor={"blue"}
                hoverColor={"white"}
                hoverBorderColor={"mainBlue"}
                customStyles={{ borderRadius: "4px", p: "8px 18px" }}
              />
            </Link>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
