// env_config.js

// This file uses CommonJS syntax because Next.js 14.x does not support ES modules in this config file
// NOTE: this file is deprecated and being replaced by direct environment variables
/**
 * Configuration object for different environments
 * This file contains environment-specific settings for the application.
 * It includes configurations for local development, sandbox, development, staging, and production environments.
 * It also includes configurations for media, Strapi CMS, and Sentry error tracking.
 */

const packageJson = require("./package.json");
const NODE_VERSION = packageJson.engines.node;

const currentEnv = process.env.NEXT_ENVIRONMENT;
/**
 * @type {Record<string, any>}
 */
const ENVIRONMENT_CONFIGS = {
  nodeVersion: NODE_VERSION,
  misc: {
    atlasAppId: "4d7wu8hn5u",
    googleTagManagerId: "GTM-P4T6Z2M",
  },
  googleMaps: {
    projectId: "forward-ether-353919",
    apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    signingSecret: process.env.GOOGLE_MAPS_API_SECRET,
  },
  // firebase environment for authentication, keys, and SSO
  firebase: {
    // Use the domain that serves our app for auth to avoid third-party cookie issues
    authDomain: process.env.NEXT_PUBLIC_HOSTNAME || "alpha.aventure.vc",
    projectId: "aventure-app-auth",
    storageBucket: "aventure-app-auth.appspot.com",
    messagingSenderId: "84246390852",
    appId: "1:84246390852:web:60ed2f4771389359733411",
    measurementId: "G-SRDYW9ZWM5",
    repoName: "front-end",
    basicAuthActivated: process.env.NEXT_PUBLIC_TEST === "true",
    accountSettingsInvestor: "user",
    linkedInAuth: {
      clientId: "861zpwwsxbbfd8",
      clientSecret: "LINKEDIN_OAUTH_SECRET",
      scope: "r_liteprofile r_emailaddress",
      authorizationUrl: "https://www.linkedin.com/oauth/v2/authorization",
      tokenUrl: "https://www.linkedin.com/oauth/v2/accessToken",
      profileUrl: "https://api.linkedin.com/v2/me",
      profileParams:
        "(id,localizedFirstName,localizedLastName,profilePicture(displayImage~:playableStreams))",
      redirectPath: "/api/auth/callback/linkedin",
    },
    aliases: ["auth", "authentication", "sso", "firebase"],
  },
  googleOAuth: {
    clientId: "NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID",
    clientSecret: "GOOGLE_OAUTH_SECRET",
    redirectPath: "/api/auth/callback/google",
  },
  // Strapi CMS configuration for blog, news, and services
  strapi: {
    hostname: "strapi.aventure.vc",
    url: "https://strapi.aventure.vc",
    aliases: ["cms", "blog", "services"],
  },
  // Sentry error tracking configuration
  sentry: {
    organization: "aventure",
    dsn: "https://55090d2801836087e5189a8fd6bf6ad4@o4506261163802624.ingest.us.sentry.io/4506261194604544",
    enabled: true,
    project: "front-end",
  },
    // Aurora environment
  aurora: {
    url: "https://aurora.aventure.vc",
    apiUrl: "https://api.aurora.aventure.vc/api",
    apiImageHostName: "https://images.aventure.vc",
    apiNewsImageHostName: "https://images.aventure.vc/images/news/",
    apiHostName: "api.aurora.aventure.vc",
    googleCloudProject: "sandbox-417216",
    googleCloudServiceAccount: "sandbox-417216@appspot.gserviceaccount.com",
    aliases: ["aurora"],
  },
  // Alpha environment
  alpha: {
    url: "https://alpha.aventure.vc",
    apiUrl: "https://api.alpha.aventure.vc/api",
    apiImageHostName: "https://images.aventure.vc",
    apiNewsImageHostName: "https://images.aventure.vc/images/news/",
    apiHostName: "api.alpha.aventure.vc",
    googleCloudProject: "sandbox-417216",
    googleCloudServiceAccount: "sandbox-417216@appspot.gserviceaccount.com",
    aliases: ["alpha"],
  },
  // Sandbox environment
  sandbox: {
    url: "https://sandbox.aventure.vc",
    apiUrl: "https://api.sandbox.aventure.vc/api",
    apiImageHostName: "https://images.aventure.vc",
    apiNewsImageHostName: "https://images.aventure.vc/images/news/",
    apiHostName: "api.sandbox.aventure.vc",
    googleCloudProject: "sandbox-417216",
    googleCloudServiceAccount: "sandbox-417216@appspot.gserviceaccount.com",
    aliases: ["sandbox"],
  },
  // Development environment
  development: {
    url: "https://dev.aventure.vc",
    apiUrl: "https://api.dev.aventure.vc/api",
    apiImageHostName: "https://images.aventure.vc",
    apiNewsImageHostName: "https://images.aventure.vc/images/news/",
    apiHostName: "api.dev.aventure.vc",
    googleCloudProject: "development-417216",
    googleCloudServiceAccount: "development-417216@appspot.gserviceaccount.com",
    aliases: ["dev", "development"],
  },
  // Staging environment
  staging: {
    url: "https://staging.aventure.vc",
    apiUrl: "https://api.staging.aventure.vc/api",
    apiImageHostName: "https://images.aventure.vc",
    apiNewsImageHostName: "https://images.aventure.vc/images/news/",
    apiHostName: "api.staging.aventure.vc",
    googleCloudProject: "staging-417216",
    googleCloudServiceAccount:
      "staging-service-account@staging-417216.iam.gserviceaccount.com",
    aliases: ["qa", "quality-assurance", "staging", "stage"],
  },
  // Production environment
  production: {
    url: "https://aventure.vc",
    apiUrl: "https://api.aventure.vc/api",
    apiImageHostName: "https://images.aventure.vc",
    apiNewsImageHostName: "https://images.aventure.vc/images/news/",
    apiHostName: "api.aventure.vc",
    googleCloudProject: "production-417216",
    googleCloudServiceAccount: "production-417216@appspot.gserviceaccount.com",
    aliases: ["prod", "production", "main", "master"],
  },
  // Local development environment
  local: {
    url: "http://localhost:3001",
    apiUrl: "http://localhost:3000/api",
    apiImageHostName: "https://images.aventure.vc",
    apiNewsImageHostName: "https://images.aventure.vc/images/news/",
    apiHostName: "localhost:3000",
    googleCloudProject: "sandbox-417216",
    googleCloudServiceAccount: "sandbox-417216@appspot.gserviceaccount.com",
    aliases: ["local"],
  },
};

/**
 * List of secrets to be loaded from Google Secret Manager
 */

const buildSecrets = [
  "NEXT_PUBLIC_FIREBASE_API_KEY",
  "NEXT_PUBLIC_GOOGLE_MAPS_API_KEY",
  "NEXT_PUBLIC_GOOGLE_OAUTH_CLIENT_ID",
  "GOOGLE_OAUTH_SECRET",
  "LINKEDIN_OAUTH_SECRET",
  "NEXTAUTH_SECRET",
  "NEXT_PUBLIC_SENTRY_DSN",
  "NEXT_PUBLIC_STRAPI_API_TOKEN",
  "SENTRY_AUTH_TOKEN",
];

/**
 * Function to check if running in a GCP environment
 */
function isGCPEnvironment() {
  return Boolean(process.env.GOOGLE_CLOUD_PROJECT);
}

/**
 * Function to load secrets
 */
function loadSecrets() {
  const secrets = {};

  // Only attempt to retrieve secrets from GCP Secret Manager if in GCP environment
  if (isGCPEnvironment()) {
    try {
      // Require the secret retrieval utility
      const { getSecret } = require("./src/utils/env/googleSecretRetrieval");

      // Load secrets from GCP Secret Manager
      for (const secretName of buildSecrets) {
        let secretValue = process.env[secretName];

        if (!secretValue) {
          // Try to retrieve the secret from GCP Secret Manager
          try {
            secretValue = getSecret(secretName); // We'll modify getSecret to have a synchronous version
            console.log(
              `Retrieved secret ${secretName} from GCP Secret Manager`,
            );
          } catch (error) {
            console.error(`Failed to retrieve secret ${secretName}`);
          }
        }
        
        secrets[secretName] = secretValue;
      }
    } catch (error) {
      console.error("Failed to load secrets from GCP Secret Manager");
    }
  }

  return secrets;
}

// Load secrets synchronously
const secrets = loadSecrets();

/**
 * Next.js configuration
 */
const nextConfig = {
  reactStrictMode: true,
  output: "standalone",
  poweredByHeader: false,
  compress: true,
};

const url = ENVIRONMENT_CONFIGS[currentEnv]?.url;
const apiUrl = ENVIRONMENT_CONFIGS[currentEnv]?.apiUrl;
const apiImageHostName = ENVIRONMENT_CONFIGS[currentEnv]?.apiImageHostName;
const strapiHostUrl = ENVIRONMENT_CONFIGS?.strapi?.url;
const strapi = {
  apiToken: process.env.NEXT_PUBLIC_STRAPI_API_TOKEN,
};
const apiNewsImageHostName =
  ENVIRONMENT_CONFIGS[currentEnv]?.apiNewsImageHostName;
const getRedirectUrl = (baseUrl, path) => {
  const url = baseUrl.endsWith("/") ? baseUrl.slice(0, -1) : baseUrl;
  return `${url}${path}`;
};

const getRedirectUrls = () => {
  const baseUrl = ENVIRONMENT_CONFIGS[currentEnv]?.url;
  if (!baseUrl) {
    console.error(`No URL configured for environment: ${currentEnv}`);
    return { googleRedirectUrl: "", linkedInRedirectUrl: "" };
  }
  const googleRedirectUrl = getRedirectUrl(
    baseUrl,
    ENVIRONMENT_CONFIGS.googleOAuth.redirectPath,
  );
  const linkedInRedirectUrl = getRedirectUrl(
    baseUrl,
    ENVIRONMENT_CONFIGS.firebase.linkedInAuth.redirectPath,
  );
  return {
    googleRedirectUrl,
    linkedInRedirectUrl,
  };
};

module.exports = {
  ENVIRONMENT_CONFIGS,
  buildSecrets,
  secrets,
  nextConfig,
  apiUrl,
  url,
  apiImageHostName,
  strapiHostUrl,
  strapi,
  currentEnv,
  apiNewsImageHostName,
  googleOAuth: {
    ...ENVIRONMENT_CONFIGS.googleOAuth,
    get redirectUrl() {
      return getRedirectUrls().googleRedirectUrl;
    },
  },
  linkedInOAuth: {
    ...ENVIRONMENT_CONFIGS.firebase.linkedInAuth,
    get redirectUrl() {
      return getRedirectUrls().linkedInRedirectUrl;
    },
  },
  NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,
  getRedirectUrl,
  getRedirectUrls,
};
