export const menuItemsFooter = [
  {
    section: "Research",
    items: [
      {
        id: 3,
        text: "Research Companies",
        pathName: "/companies",
        alt: "Research Companies",
      },
      {
        id: 5,
        text: "Research Investors",
        pathName: "/investors",
        alt: "Research Investors",
      },
      {
        id: 6,
        text: "Research People",
        pathName: "/people",
        alt: "Research People",
      },
    ],
  },
  {
    section: "aVenture",
    items: [
      {
        id: 2,
        text: "Feature Requests",
        pathName: "https://aventure.canny.io/feature-requests",
        alt: "Feature Requests",
      },
      {
        id: 5,
        text: "Report a Problem",
        alt: "Report",
      },
      { id: 6, text: "Blog", pathName: "/blog", alt: "Blog" },
    ],
  },
];
