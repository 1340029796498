"use client";

import { h_700_14_16, h_700_16_100_32, h_700_16_16 } from "@/styles/fontStyles";
import {
  Avatar,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { usePathname } from "next/navigation";
import { useSelector } from "react-redux";

import ChevronDownSvg from "@/assets/svg/chevron-down.svg";
import { useActions } from "@/hooks/useActions";
import { useUserSignOutMutation } from "@/services/auth.service";
import contentSelectors from "@/store/content/content.selectors";
import { signOut } from "next-auth/react";
import Link from "next/link";

export const Dropdown = ({
  textColor = "white",
  customStyles,
  titleButton,
  menuList,
  menuListBgColor = "white",
  isSubMenu = false,
  src = false,
  userName = false,
  left = "0px",
  minW,
  isProfile,
}) => {
  const pathName = usePathname();

  const { setCurrentNavLink, userLogOut } = useActions();
  const currentNavLink = useSelector(contentSelectors.getCurrentNavLink());

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userSignOut] = useUserSignOutMutation();

  const logOutHandler = () => {
    userSignOut().then(
      (res) => {
        if(res?.data?.success){
          window.location.href='/'
          userLogOut() 
          signOut()
        }
      }
    );
  };

  const checkIsUnderLine = (titleButton) => {
    return (
      titleButton === "Research" &&
      ["companies", "funds", "people"].includes(pathName.split("/")[1])
    );
  };

  return (
    <Menu onClose={onClose} isOpen={isOpen} onOpen={onOpen}>
      <MenuButton
        as={Button}
        bg={"none"}
        borderRadius={"0px"}
        color={textColor}
        _hover={{ bg: "none" }}
        _focus={{ bg: "none" }}
        _active={{ bg: "none" }}
        px={"0px"}
        h={"full"}
        onClick={() => {
          setCurrentNavLink(titleButton);
        }}
        {...(!isSubMenu && !src && { borderBottom: "2px" })}
        borderColor={
          !isSubMenu &&
          !src &&
          currentNavLink === titleButton &&
          isOpen &&
          !isProfile
            ? "red"
            : "transparent"
        }
        {...customStyles}
      >
        {!isProfile && (
          <Flex align={"center"} gap={"4px"}>
            <Text
              {...h_700_16_16}
              {...(isSubMenu && { borderBottom: "2px" })}
              borderColor={
                checkIsUnderLine(titleButton)
                  ? "red"
                  : isSubMenu && currentNavLink === titleButton && isOpen
                  ? "red"
                  : "transparent"
              }
            >
              {titleButton}
            </Text>
            <Box
              transform={isOpen ? "rotate(180deg)" : "rotate(0deg)"}
              transition={"transform 0.3s ease"}
              mb={"4px"}
            >
              <ChevronDownSvg stroke={textColor} />
            </Box>
          </Flex>
        )}
        {isProfile && (
          <Avatar
            size="sm"
            w={"36px"}
            h={"36px"}
            bg={"borderGray"}
            borderWidth={"2px"}
            borderColor={"black"}
            src={src}
          />
        )}
      </MenuButton>
      <MenuList
        bg={menuListBgColor}
        border={"none"}
        position="absolute"
        top="-8px"
        left={left}
        borderTopLeftRadius={"0px"}
        borderTopRightRadius={"0px"}
        zIndex={1100}
        minW={minW || "fit-content"}
        p={"12px"}
      >
        {userName && (
          <Box py={"10px"} mt={"8px"}>
            <Text {...h_700_16_100_32} color={"grey"}>
              {userName}
            </Text>
          </Box>
        )}
        {menuList?.map((item, index) => {
          return (
            <MenuItem
              key={`${item?.title}-${index}`}
              bg={menuListBgColor}
              color={textColor}
              _hover={{ bg: "none" }}
              maxW={"fit-content"}
              p={"0px"}
            >
              <Link
                href={item?.route}
                alt={item?.title}
                onClick={() => {
                  item?.title === "Log Out" && logOutHandler();
                }}
              >
                <Box p={"8px 12px 8px 0px"}>
                  <Text
                    maxW={"fit-content"}
                    {...h_700_14_16}
                    borderBottom={"2px"}
                    borderColor={"transparent"}
                    transition={"border-color 0.3s ease"}
                    _hover={{
                      borderColor: "red",
                      color: item?.title === "Log Out" ? "red" : textColor,
                    }}
                  >
                    {item?.title}
                  </Text>
                </Box>
              </Link>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};
