// src/components/companies/companiesList/newCompaniesList/data/lists.tsx

import CompanySvg from "@/assets/svg/companiesList/company.svg";
import StageSvg from "@/assets/svg/companiesList/stage.svg";
import AcceleratorSvg from "@/assets/svg/companiesList/accelerator.svg";
// import AcceleratorBatchSvg from "@/assets/svg/companiesList/accelerator-batch.svg";
import YearFoundSvg from "@/assets/svg/companiesList/year-found.svg";
import RaisedInRoundSvg from "@/assets/svg/companiesList/raised-in-round.svg";
import TagsSvg from "@/assets/svg/companiesList/tags.svg";
import DescriptionSvg from "@/assets/svg/companiesList/description.svg";
import HqSvg from "@/assets/svg/companiesList/hq.svg";
import EmployeesSvg from "@/assets/svg/companiesList/employees.svg";
import CurrencySvg from "@/assets/svg/companiesList/currency.svg";
// import PitchdeckSvg from "@/assets/svg/companiesList/pitchdeck.svg";
// import LegalDomicileSvg from "@/assets/svg/companiesList/legal-domicile.svg";
// import InvestorAvailabilitySvg from "@/assets/svg/companiesList/investor-availability.svg";
import MainProductsSvg from "@/assets/svg/companiesList/main-products.svg";
// import FundAvailabilitySvg from "@/assets/svg/companiesList/fund-availability.svg";
import OperatingStatusSvg from "@/assets/svg/companiesList/operating-status.svg";
// import VerifiedStatusSvg from "@/assets/svg/companiesList/verified-status.svg";
import CustomerTypeSvg from "@/assets/svg/companiesList/customer-type.svg";
import ModelTypeSvg from "@/assets/svg/companiesList/model-type.svg";
import OwnershipTypeSvg from "@/assets/svg/companiesList/ownership-type.svg";
import RevenueTypeSvg from "@/assets/svg/companiesList/revenue-type.svg";
import TechnologyUsedSvg from "@/assets/svg/companiesList/technology-used.svg";
import LinksSvg from "@/assets/svg/companiesList/links.svg";
import LastUpdateSvg from "@/assets/svg/companiesList/last-update.svg";
import TotalRaisedSvg from "@/assets/svg/companiesList/total-raised.svg";

export const listFields = [
  {
    id: "company",
    isSort: true,
    isTable: true,
    title: "Company",
    value: "company",
    orderBy: "nameBrand",
    groupedBy: "",
    filterField: "",
    icon: <CompanySvg />,
  },
  {
    id: "stage",
    isGroup: true,
    // isSort: true,
    isSort: false,
    isShow: true,
    isTable: true,
    title: "Stage",
    value: "stage",
    orderBy: "",
    groupedBy: "",
    filterField: "Investment Stage",
    icon: <StageSvg />,
  },
  {
    id: "accelerator",
    // isGroup: true,
    // isSort: true,
    isGroup: false,
    isSort: false,
    isShow: true,
    isTable: true,
    title: "Accelerator",
    value: "accelerator",
    orderBy: "",
    groupedBy: "",
    filterField: "Accelerator",
    icon: <AcceleratorSvg />,
  },
  {
    id: "yearFounded",
    // isGroup: true,
    isGroup: false,
    isSort: true,
    isShow: true,
    isTable: true,
    title: "Founded",
    // value: "yearFounded",
    orderBy: "dateYearEstablished",
    groupedBy: "",
    value: "dateYearEstablished",
    filterField: "Year Founded",
    icon: <YearFoundSvg />,
  },
  {
    id: "customerType",
    isGroup: true,
    isSort: false,
    isShow: true,
    isTable: true,
    title: "Customer Type",
    value: "customerType",
    orderBy: "",
    groupedBy: "",
    filterField: "Customer Type(s)",
    icon: <CustomerTypeSvg />,
  },
  {
    id: "revenueType",
    isGroup: true,
    isSort: false,
    isShow: true,
    isTable: true,
    title: "Revenue Type",
    value: "revenueType",
    orderBy: "",
    groupedBy: "",
    filterField: "Revenue Type(s)",
    icon: <RevenueTypeSvg />,
  },
  {
    id: "tags",
    isGroup: true,
    isSort: false,
    isShow: true,
    isTable: true,
    title: "Tags",
    value: "tags",
    orderBy: "",
    groupedBy: "",
    filterField: "Sector / Industry Tags",
    icon: <TagsSvg />,
  },
  {
    id: "summary",
    isGroup: false,
    isSort: false,
    isShow: true,
    isTable: true,
    title: "Summary",
    value: "summary",
    orderBy: "",
    groupedBy: "",
    filterField: "",
    icon: <DescriptionSvg />,
  },

  {
    id: "headQuoters",
    isGroup: false,
    isSort: false,
    isShow: true,
    isTable: true,
    title: "HQ",
    value: "headQuoters",
    orderBy: "",
    groupedBy: "",
    filterField: "",
    icon: <HqSvg />,
  },
  {
    id: "raisedInRound",
    // isSort: true,
    isGroup: false,
    isSort: false,
    isShow: true,
    isTable: true,
    title: "Last Round",
    value: "raisedInRound",
    orderBy: "",
    groupedBy: "",
    filterField: "",
    icon: <RaisedInRoundSvg />,
  },
  {
    id: "totalRaised",
    isGroup: false,
    isSort: false,
    // isSort: true,
    isShow: true,
    isTable: true,
    title: "Total Raised",
    value: "totalRaised",
    orderBy: "",
    groupedBy: "",
    filterField: "",
    icon: <TotalRaisedSvg />,
  },
  {
    id: "description",
    isGroup: false,
    isSort: false,
    isShow: true,
    isTable: true,
    title: "Description",
    value: "description",
    orderBy: "",
    groupedBy: "",
    filterField: "",
    icon: <DescriptionSvg />,
  },
  {
    id: "employees",
    isGroup: false,
    isSort: false,
    // isSort: true,
    isShow: true,
    isTable: true,
    title: "Employees",
    value: "employees",
    orderBy: "",
    groupedBy: "",
    filterField: "",
    icon: <EmployeesSvg />,
  },
  {
    id: "acceleratorBatch",
    // isGroup: true,
    // isSort: true,
    isGroup: false,
    isSort: false,
    isShow: true,
    isTable: true,
    title: "Batch",
    value: "acceleratorBatch",
    orderBy: "",
    groupedBy: "",
    filterField: "Accelerator Batch",
    // icon: <AcceleratorBatchSvg />,
    icon: <CurrencySvg />,
  },
  // {
  //   id: "currency",
  //   // isGroup: true,
  //   // isSort: true,
  //   isGroup: false,
  //   isSort: false,
  //   isShow: true,
  //   isTable: true,
  //   title: "Currency",
  //   value: "currency",
  //   orderBy: "",
  //   groupedBy: "",
  //   filterField: "",
  //   icon: <CurrencySvg />,
  // },
  // {
  //   id: "pitchdeck",
  //   isGroup: false,
  //   isSort: false,
  //   isShow: true,
  //   isTable: true,
  //   title: "Pitchdeck",
  //   value: "pitchdeck",
  //   orderBy: "",
  //   groupedBy: "",
  //   filterField: "",
  //   icon: <PitchdeckSvg />,
  // },
  // {
  //   id: "legalDomicile",
  //   isGroup: true,
  //   isSort: true,
  //   isShow: true,
  //   isTable: true,
  //   title: "Legal Domicile",
  //   value: "legalDomicile",
  //   orderBy: "legalDomicile",
  //   groupedBy: "",
  //   filterField: "Legal Domicile",
  //   icon: <LegalDomicileSvg />,
  // },
  // {
  //   id: 'investorAvailability',
  //   isGroup: true,
  //   isSort: true,
  //   isShow: true,
  //   isTable: true,
  //   title: 'Investor Availability',
  //   value:'investorAvailability'
  //   orderBy: '',
  //   groupedBy: '',
  //   filterField:'',
  //   icon: <InvestorAvailabilitySvg />,
  // },
  {
    id: "mainProducts",
    isGroup: true,
    isSort: false,
    isShow: true,
    isTable: true,
    title: "Main Products",
    value: "mainProducts",
    orderBy: "",
    groupedBy: "",
    filterField: "Main product(s)",
    icon: <MainProductsSvg />,
  },
  // {
  //   id: 'fundAvailability',
  //   isGroup: true,
  //   isSort: true,
  //   isShow: true,
  //   isTable: true,
  //   title: 'Fund Availability',
  //   value: 'fundAvailability',
  //   orderBy: '',
  //   groupedBy: '',
  //   filterField:'',
  //   icon: <FundAvailabilitySvg />,
  // },
  {
    id: "operatingStatus",
    // isGroup: true,
    // isSort: true,
    isGroup: false,
    isSort: true,
    isShow: true,
    isTable: true,
    title: "Status",
    value: "operatingStatus",
    orderBy: "statusOperating",
    groupedBy: "",
    filterField: "",
    icon: <OperatingStatusSvg />,
  },
  // {
  //   id: "verifiedStatus",
  //   // isGroup: true,
  //   isGroup: false,
  //   isSort: true,
  //   isShow: true,
  //   isTable: true,
  //   title: "Verified Status",
  //   value: "verifiedStatus",
  //   orderBy: "statusVerified",
  //   groupedBy: "",
  //   filterField: "",
  //   icon: <VerifiedStatusSvg />,
  // },

  {
    id: "modelType",
    isGroup: true,
    isSort: false,
    isShow: true,
    isTable: true,
    title: "Model Type",
    value: "modelType",
    orderBy: "",
    groupedBy: "",
    filterField: "Model Type(s)",
    icon: <ModelTypeSvg />,
  },
  {
    id: "ownershipType",
    isGroup: false,
    isSort: false,
    isShow: true,
    isTable: true,
    title: "Ownership Type",
    value: "ownershipType",
    orderBy: "",
    groupedBy: "",
    filterField: "",
    icon: <OwnershipTypeSvg />,
  },

  {
    id: "technologyUsed",
    isGroup: true,
    isSort: false,
    isShow: true,
    isTable: true,
    title: "Technology Used",
    value: "technologyUsed",
    orderBy: "",
    groupedBy: "",
    filterField: "Technology Used / Offered",
    icon: <TechnologyUsedSvg />,
  },
  {
    id: "links",
    isGroup: false,
    isSort: false,
    isShow: true,
    isTable: true,
    title: "Links",
    value: "links",
    orderBy: "",
    groupedBy: "",
    filterField: "",
    icon: <LinksSvg />,
  },
  {
    id: "lastUpdate",
    // isGroup: true,
    isGroup: false,
    isSort: true,
    isShow: true,
    isTable: true,
    title: "Last Update",
    value: "lastUpdate",
    orderBy: "updatedAt",
    groupedBy: "",
    filterField: "",
    icon: <LastUpdateSvg />,
  },
];

