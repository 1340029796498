import { combineReducers, configureStore, MiddlewareArray, getDefaultMiddleware } from "@reduxjs/toolkit";
import { aventureApi } from "@/services/apiService/aventureApi";
import errorSlice from "@/store/error/error.slice";
import contentSlice from "@/store/content/content.slice";
import companiesSlice from "@/store/companies/companies.slice";
import fundsSlice from "@/store/funds/funds.slice";
import fundraisingSlice from "@/store/fundraising/fundraising.slice";
import searchSlice from "./search/search.slice";
import peopleSlice from "./people/people.slice";
import tagsSlice from "./tags/tags.slice";
import authSlice from "./auth/auth.slice";
import { strapiApi } from "@/services/strapi.service";
import profileSlice from "./profile/profile.slice";
import filtersSlice from "./filters/filters.slice";
import acctFundsSlice from "./acctFunds/acctFunds.slice";
import notificationsSlice from "./notifications/notifications.slice";
import companiesListSlice from "./companiesList/companiesList.slice";
import viewsSlice from "./views/views.slice";
import newCompaniesListSlice from "./newCompaniesList/newCompaniesList.slice";
import newViewsSlice from "./newView/newView.slice";
import newListsSlice from "./newLists/newLists.slice";

const rootReducers = combineReducers({
  auth: authSlice.reducer,
  profile: profileSlice.reducer,
  companies: companiesSlice.reducer,
  companiesList: companiesListSlice.reducer,
  filters: filtersSlice.reducer,
  funds: fundsSlice.reducer,
  content: contentSlice.reducer,
  fundraising: fundraisingSlice.reducer,
  search: searchSlice.reducer,
  people: peopleSlice.reducer,
  tags: tagsSlice.reducer,
  acctFunds: acctFundsSlice.reducer,
  notifications: notificationsSlice.reducer,
  [strapiApi.reducerPath]: strapiApi.reducer,
  error: errorSlice.reducer,
  views: viewsSlice.reducer,
  newCompaniesList: newCompaniesListSlice?.reducer,
  newViews: newViewsSlice.reducer,
  newLists: newListsSlice.reducer,
  [aventureApi.reducerPath]: aventureApi.reducer,
});

export type RootState = ReturnType<typeof rootReducers>;
export type AppStore = ReturnType<typeof makeStore>;
export type AppDispatch = AppStore['dispatch'];

export const makeStore = () => {
  const defaultMiddleware = getDefaultMiddleware({
    serializableCheck: false,
  }) as MiddlewareArray<any>;

  return configureStore({
    reducer: rootReducers,
    middleware: defaultMiddleware
      .concat(aventureApi.middleware)
      .concat(strapiApi.middleware),
    devTools: process.env.NODE_ENV !== "production",
  });
};
