"use client";

import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import dynamic from 'next/dynamic';
import { TitleBlock } from "./components/titleBlock/TitleBlock";
import CompanyResearch from "./components/companyResearch";
import { RunningImageString } from "./components/runningImageString/RunningImageString";
import RiskAdjusted from "./components/riskAdjusted";
import VentureCapital from "./components/ventureCapital";
import CreateAccBlock from "./components/createAccBlock";
import { FundraisingBlock } from "./components/fundraisingRounds/FundraisingBlock";
import BgDecorationSvg from "@/assets/svg/bg-decor-desk.svg";
import { FundResearch } from "./components/fundResearch/FundResearch";
import NewsHomeLine from "../news/homeLine";
import { useEffect, useState } from "react";

const DecorationClient = dynamic(() => Promise.resolve(function Decoration() {
  const [isMobile] = useMediaQuery("(max-width: 767px)", {
    ssr: true, // Use SSR default value
    fallback: false // Fallback value for server-side
  });
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  // Return a consistent initial state for SSR
  if (!mounted) {
    return (
      <Box
        position="absolute"
        w="full"
        overflow="hidden"
        top="0px"
        left="0px"
        bottom="0px"
        right="0px"
        zIndex={-1}
      />
    );
  }

  return (
    <Box
      position="absolute"
      w="full"
      overflow="hidden"
      top="0px"
      left="0px"
      bottom="0px"
      right="0px"
      zIndex={-1}
    >
      <Box
        position="absolute"
        zIndex={-1}
        display={{ base: "flex", md: "none", xl: "flex" }}
        top={{ base: "-74px", md: "0px" }}
        left={{ base: "-112px", lg: "20%" }}
        w={isMobile ? "1065px" : "1584px"}
        h={isMobile ? "868px" : "1290px"}
      >
        <BgDecorationSvg
          style={{
            width: isMobile ? "1065px" : "1584px",
            height: isMobile ? "868px" : "1290px",
          }}
        />
      </Box>
    </Box>
  );
}), {
  ssr: false // Disable SSR for this component
});

export const Research = ({
  companiesLine,
  fundraising,
  funds,
  tags,
  newsLine,
}) => {
  const [isMobile] = useMediaQuery("(max-width: 767px)");

  return (
    <div className="bg-research">
      <DecorationClient />
      <Flex flexDir={"column"}>
        <TitleBlock {...{ tagList: tags }} />
        <RunningImageString {...{ currentCompaniesList: companiesLine }} />
        <CompanyResearch {...{ companiesList: companiesLine }} />
        <NewsHomeLine
          {...{ news: newsLine?.slice(0, 20), title: "Latest Company News" }}
        />
        <FundraisingBlock {...{ fundraising, tags }} />
        {isMobile ? (
          <FundResearch {...{ funds }} />
        ) : (
          <Flex {...{ mt: { base: "40px", md: "40px", lg: "40px" } }}></Flex>
        )}
        <NewsHomeLine
          {...{
            news: newsLine?.slice(20),
            title: "VC and Private Fund News",
          }}
        />
        <Box mt={{ base: "20px", md: "60px", lg: "40px" }}>
          <RiskAdjusted />
        </Box>
        <Box
          mt={{ base: "8px", md: "40px", lg: "0px" }}
          mb={{ base: "80px", md: "80px", lg: "80px" }}
        >
          <VentureCapital />
        </Box>
        <Box mb={{ md: "78px", xl: "119px" }}>
          <CreateAccBlock />
        </Box>
      </Flex>
    </div>
  );
};
