import { mobileListMenu } from "@/assets/data/menuList/menuList";
import ArrowRightSvg from "@/assets/svg/arrow_right_17.svg";
import BellIcon from "@/assets/svg/bell-noti.svg";
import BurgerSvg from "@/assets/svg/burger.svg";
import CloseBurgerSvg from "@/assets/svg/close-burger.svg";
import SignOutSVG from "@/assets/svg/sign-out.svg";
import { useActions } from "@/hooks/useActions";
import { useUserSignOutMutation } from "@/services/auth.service";
import { getFromLocalStorage } from "@/services/localStorage.service";
import { getUnreadNotificationsData } from "@/store/notifications/notifications.selectors";
import { getUserProfileInfo } from "@/store/profile/profile.selectors";
import {
  h_400_12_135_48,
  h_400_14_normal_28,
  h_400_16_normal_32,
  h_700_14_120_28,
  h_700_16_100_32,
} from "@/styles/fontStyles";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Menu,
  MenuItem,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { signOut } from "next-auth/react";
import Link from "next/link";

import { useSelector } from "react-redux";
import { CustomElement } from "../../box/CustomElement";

export const HeaderNavigationMobile = ({userIsVerify}) => {
  const controlDrawerMenu = useDisclosure();

  return (
    <>
      <Flex
        minW={"40px"}
        onClick={() => {
          controlDrawerMenu?.onToggle();
        }}
        position={"relative"}
        cursor={"pointer"}
        zIndex={"500"}
      >
        {controlDrawerMenu?.isOpen ? <CloseBurgerSvg /> : <BurgerSvg />}
      </Flex>
      <DrawerMenu {...{ control: controlDrawerMenu, userIsVerify: userIsVerify }} />
    </>
  );
};

function DrawerMenu({ control, userIsVerify }) {
  const { userLogOut } = useActions();
  const profile = useSelector(getUserProfileInfo());
  const [userSignOut] = useUserSignOutMutation();
  const unreadNotifications = useSelector(getUnreadNotificationsData());

  const userLogOutHandler = () => {
    userSignOut().then(
      (res) => res?.data?.success && (userLogOut(), signOut())
    );
  };

  const isDev = process.env.NEXT_ENVIRONMENT === "development";

  return (
    <>
      <Drawer
        isOpen={control?.isOpen}
        placement="left"
        onClose={control?.onClose}
      >
        <DrawerOverlay style={{ top: "60px" }} />
        <DrawerContent
          style={{
            top: "60px",
            minWidth: "280px",
            maxWidth: "310px",
            padding: "24px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <DrawerBody p={"0px"} style={{ flexGrow: 1 }}>
            <Flex alignItems={"center"}>
              {userIsVerify ? (
                <>
                  <Avatar
                    w={"36px"}
                    h={"36px"}
                    borderWidth={"2px"}
                    borderColor={"black"}
                    size="md"
                    src={profile?.userPhotoUpload}
                  />
                  <Flex flexDirection={"column"} ml={"8px"}>
                    <Text {...h_700_16_100_32}>{profile?.userFullNameId}</Text>
                    <Text mt={"8px"} {...h_400_14_normal_28}>
                      {profile?.email}
                    </Text>
                  </Flex>
                </>
              ) : (
                <Flex
                  flexDir={"column"}
                  gap={"12px"}
                  w={"full"}
                  align={"center"}
                >
                  <Text {...h_400_12_135_48} color={"gray"}>
                    Login or signup to your account
                  </Text>
                  <Flex justify={"center"} gap={"12px"}>
                    <Link
                      href={"/sign-up"}
                      alt={"Sign Up"}
                      style={{ outline: "none" }}
                    >
                      <CustomElement
                        variant={"redBtn"}
                        title={"Sign Up"}
                        customStyle={{
                          ...h_700_14_120_28,
                          p: "11px 18px 8px 18px",
                        }}
                      />
                    </Link>

                    <Link href={"/sign-in"} alt={"Sign In"}>
                      <CustomElement
                        variant={"blueBtn"}
                        customStyle={{ p: "11px 13px 8px 18px" }}
                      >
                        <Flex color={"white"} align={"center"}>
                          <Text {...h_700_14_120_28}>Login</Text>
                          <ArrowRightSvg />
                        </Flex>
                      </CustomElement>
                    </Link>
                  </Flex>
                </Flex>
              )}
            </Flex>
            <Accordion allowMultiple mt={"30px"} maxW={"204px"}>
              <>
                <Box color={"red"} flex="1" textAlign="left" py={"8px"}>
                  Home
                </Box>
              </>

              {mobileListMenu({ isDev }).map((item) => {
                if (!userIsVerify && item?.title === "Account") {
                  return null;
                }
                return (
                  <AccordionItem key={item.id} border={"none"} p={0}>
                    {({ isExpanded }) => (
                      <>
                        <h2>
                          <AccordionButton p={0} py={"8px"}>
                            <Box
                              flex="1"
                              textAlign="left"
                              {...h_400_16_normal_32}
                            >
                              {item.title}
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                        </h2>
                        <AccordionPanel
                          pb={4}
                          display={isExpanded ? "block" : "none"}
                        >
                          <Menu>
                            {item.subitems &&
                              item.subitems.map((subitem) => (
                                <MenuItem key={subitem.title}>
                                  <Link
                                    href={subitem?.route}
                                    alt={subitem?.title}
                                    onClick={() => {
                                      control.onClose();
                                    }}
                                  >
                                    {subitem.title}
                                  </Link>
                                </MenuItem>
                              ))}
                          </Menu>
                        </AccordionPanel>
                      </>
                    )}
                  </AccordionItem>
                );
              })}
            </Accordion>
          </DrawerBody>

          <Box>
            {userIsVerify ? (
              <Link
                href={"/"}
                alt={"Home"}
                style={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: "fit-content",
                }}
                onClick={userLogOutHandler}
              >
                <Text mr={"12px"}>Log out</Text>
                <SignOutSVG />
              </Link>
            ) : null}
            <Flex alignItems={"center"}>
              <Link
                href={"/notifications"}
                alt={"Notifications"}
                onClick={() => control.onClose()}
              >
                <Box position={"relative"}>
                  <BellIcon />
                  {unreadNotifications?.length ? (
                    <Box
                      pos={"absolute"}
                      top={"6px"}
                      right={"4px"}
                      borderRadius={"50%"}
                      bg={"red"}
                      h={"8px"}
                      w={"8px"}
                    ></Box>
                  ) : null}
                </Box>
              </Link>
            </Flex>
          </Box>
        </DrawerContent>
      </Drawer>
    </>
  );
}
