import { h_700_14_100 } from "@/styles/fontStyles";
import { Box, Button } from "@chakra-ui/react";
// import Link from "next/link";
import React from "react";

function SeeMoreBtn({ onClick, btnText, customStyles, rightIcon = false }) {
  return (
    <Box textAlign={"center"}>
      <Button
        _hover={{ color: "mainBlue" }}
        _active={{ backgroundColor: "transparent" }}
        {...h_700_14_100}
        color={"grey"}
        bgColor={"transparent"}
        onClick={onClick}
        {...customStyles}
        {...(rightIcon ? { rightIcon } : {})}
      >
        {btnText}
      </Button>
    </Box>
  );
}

export default SeeMoreBtn;
