"use client";

import { usePathname, useRouter } from "next/navigation";
import { MainWrapper } from "../box/MainWrapper";
import { SubHeader } from "./SubHeader";
import { HeaderNavigation } from "./components/HeaderNavigation";

import LogoIcon from "@/assets/svg/new_logo_alpha.svg";
import LogoIconMobile from "@/assets/svg/new_logo_alpha_mobile.svg";
import { useActions } from "@/hooks/useActions";
import { useBreakPoints } from "@/hooks/useBreakPoints";
import { useLazyGetSearchDataQuery } from "@/services/search.service";
import { Flex } from "@chakra-ui/react";
import Link from "next/link";
import { useRef, useState, useEffect } from "react";
import { SearchInput } from "../inputs/SearchInput";
import { HeaderNavigationMobile } from "./components/HeaderNavigationMobile";
import SearchCompanies from "@/components/companies/companiesList/newCompaniesList/SearchCompanies";

export const Header = ({ userIsVerify }) => {
  const initialized = useRef(false);
  const pathName = usePathname();
  const router = useRouter();
  const { isMobile } = useBreakPoints();
  const { setCurrentNavLink, setUserIsVerify } = useActions();
  const [searchData, setSearchData] = useState("");
  const isDev =
    process.env.NEXT_ENVIRONMENT === "development" ||
    process.env.NEXT_ENVIRONMENT === "local";

  const buttonsArray = [{ id: 3, title: "Contact", route: "/contact" }];

  const isList = pathName.endsWith("/companies");

  useEffect(() => {
    if (!initialized.current && setUserIsVerify) {
      setUserIsVerify(userIsVerify);
      initialized.current = true;
    }
  }, [userIsVerify, setUserIsVerify]);

  const LogoSvg = isMobile ? LogoIconMobile : LogoIcon;

  const [getSearchData] = useLazyGetSearchDataQuery();

  const pathsForBoxShadow = [
    "/investing",
    "/management",
    "/research",
    "/startup",
    "/contact",
  ];

  const checkPath = (array, path) => {
    return array.includes(path);
  };

  const handlerKeyDown = (e) => {
    if (e.key === "Enter" && e.target.value?.trim() !== "") {
      getSearchData({ search: e.target.value });
      router.push(`/search?search=${e.target.value}`);
    } else {
      if (
        e.key === "Enter" &&
        e.target.value?.trim() === "" &&
        pathName === "/search"
      ) {
        router.push(`/search`);
      }
    }
  };

  const onClickIconLeft = () => {
    if (searchData?.trim() !== "") {
      getSearchData({ search: searchData });
      router.push(`/search?search=${searchData}`);
    }
  };

  return (
    <>
      <MainWrapper
        customStyleOuter={{
          borderBottomRadius: "4px",
          bgColor: "mainBlue",
          h: isMobile ? "60px" : "50px",
          px: isMobile ? "24px" : "40px",
          position: "relative",
        }}
        customStyleInner={{
          flexDir: "row",
          justify: "space-between",
          align: "center",
          h: "100%",
          gap: isMobile ? "14px" : "14p",
          maxW: "100%",
        }}
      >
        <Flex minW={isMobile ? "fit-content" : "auto"}>
          <Link
            href={pathName !== "/" ? "/" : "#"}
            alt={"Home"}
            cursor={pathName !== "/" ? "pointer" : "default"}
            onClick={() => {
              setCurrentNavLink(null);
            }}
          >
            <LogoSvg cursor={pathName !== "/" ? "pointer" : "default"} />
          </Link>
        </Flex>
        {!isList && isMobile && pathName !== "/" && (
          <SearchInput
            {...{
              placeholder: "Search aVenture",
              data: searchData,
              setData: setSearchData,
              handlerKeyDown: handlerKeyDown,
              onClickIconLeft,
              customStyleInputGroup: {
                w: "full",
                h: "30px",
                border: "2px solid",
                borderColor: "borderGray",
                maxW: "400px",
                borderRadius: "4px",
                p: "8px",
              },
            }}
          />
        )}

        {isList && isMobile ? <SearchCompanies /> : null}
        {!isMobile ? (
          <>{isDev ? <HeaderNavigation buttonsArray={buttonsArray} /> : null}</>
        ) : (
          <HeaderNavigationMobile {...{userIsVerify}} />
        )}
      </MainWrapper>
      <MainWrapper
        customStyleOuter={{
          display: isMobile ? "none" : "flex",
          bgColor: isMobile ? "dashboardBackground" : "white",
          borderBottom: isMobile ? "1px solid" : "1px solid",
          borderColor: isMobile ? "rgba(126, 134, 158, 0.30);" : "white",
          boxShadow: isMobile
            ? "none"
            : "0px 0px 14px 0px rgba(114, 114, 114, 0.25)",
          h: "54px",
          px: isMobile ? "24px" : "40px",
          ...(checkPath(pathsForBoxShadow, pathName)
            ? { position: "relative", zIndex: "1000" }
            : null),
        }}
        customStyleInner={{
          flexDir: "row",
          justify: "space-between",
          align: "center",
          h: "100%",
          maxW: "100%",
        }}
      >
        <SubHeader {...{ userIsVerify }} />
      </MainWrapper>
    </>
  );
};
