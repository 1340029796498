// src/utils/logger.ts

// This file uses dual CommonJS/ESM syntax to support both Next.js config and TypeScript modules
import winston from 'winston';

const logLevel = process.env.NODE_ENV === "production" ? "error" : "warn";

const logger = winston.createLogger({
  level: logLevel,
  format: winston.format.simple(),
  transports: [
    new winston.transports.Console({
      format: winston.format.colorize({ all: true }),
      // Only show errors in production
      silent: process.env.NODE_ENV === 'production'
    }),
  ],
});

// Simplified logOnce function
const logOnce = (level: string, message: string) => {
  if (process.env.NODE_ENV !== 'production') {
    logger[level](message);
  }
};

// Export for ESM
export { logger, logOnce };

// Export for CommonJS
module.exports = { logger, logOnce };
