import { aventureApi } from "@/services/apiService/aventureApi";
import { axiosService,axiosAuthService } from "@/services/apiService/axiosService";
import { authActions } from "@/store/auth/auth.slice";
import {
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { Cookies } from "react-cookie";

import { loadingError } from "./error.service";
import { removeFromLocalStorage } from "./localStorage.service";
import { auth } from "src/firebase/firebase.config";
import { currentEnv, ENVIRONMENT_CONFIGS } from "env_config";

const baseUrl = ENVIRONMENT_CONFIGS[currentEnv]?.apiUrl || "";

const addCookie = ({ userId, roles }) => {
  document.cookie = `isLoggedIn=${userId}; path=/; expires=Tue, 19 Jan 2038 03:14:07 GMT`;
  document.cookie = `isAdmin=${roles}; path=/; expires=Tue, 19 Jan 2038 03:14:07 GMT`;
};

const authApi = aventureApi.injectEndpoints({
  endpoints: (builder) => ({
    userSignIn: builder.mutation({
      async queryFn({ email, password, authCode, onClose }, { dispatch }) {
        try {
          const { data } = await axiosAuthService.post(
            `/api/auth/email-signin`,
            {
              email,
              password,
              authCode,
            }
           );

          localStorage.setItem("pageCount", "0");
          authCode && onClose();

          return { data };
        } catch (error) {
          return {
            error: error.response?.data?.message || error?.message || error,
          };
        }
      },
    }),

    googleAuth: builder.mutation({
      async queryFn(
        {
          access_token,
          authCode,
          onOpen,
          setProvider,
          setAccessToken,
          onClose,
        },
        { dispatch }
      ) {
        try {
          const { data } = await axiosService.post(`/app/auth/google`, {
            access_token,
            authCode,
            redirect_uri: `${ENVIRONMENT_CONFIGS[currentEnv].url}${ENVIRONMENT_CONFIGS.googleOAuth.redirectPath}`,
          });
          dispatch(authActions.setUserIsVerify(data?.userId));
          addCookie({ userId: data?.userId, roles: data.roles });
          localStorage.setItem("pageCount", "0");
          authCode && onClose();

          return { data };
        } catch (error) {
          error.response?.data?.message === "Need 2fa" &&
            setProvider("google");
            setAccessToken(access_token);
            onOpen();
          error.response?.data?.message !== "Need 2fa" &&
            dispatch(loadingError(error));
          return {
            error: error.response?.data?.message || error?.message || error,
          };
        }
      },
      invalidatesTags: (res, err, arg) =>
        arg?.is_connect && [{ type: "profile" }],
    }),

    linkedInAuth: builder.mutation({
      async queryFn({ access_token, authCode, onClose }, { dispatch }) {
        try {
          const { data } = await axiosService.post(
            `/app/auth/linkedin`,
            {
              access_token,
              authCode,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          dispatch(authActions.setUserIsVerify(data?.userId));
          addCookie({ userId: data?.userId, roles: data.roles });
          localStorage.setItem("pageCount", "0");
          authCode && onClose();
          return { data };
        } catch (error) {
          dispatch(authActions.setIsLinkedIn2FA(error.response?.data?.message));
          error.response?.data?.message !== "Need 2fa" &&
            dispatch(loadingError(error));
          return {
            error: error.response?.data?.message || error?.message || error,
          };
        }
      },
      invalidatesTags: (res, err, arg) =>
        arg?.is_connect && [{ type: "profile" }],
    }),

    userSignUp: builder.mutation({
      async queryFn(options, { dispatch }) {
        try {
          const { data } = await axiosAuthService.post(`/api/auth/signup`, options);

          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return {
            error: error.response?.data?.message || error?.message || error,
          };
        }
      },
    }),
    verifyEmail: builder.mutation({
      async queryFn(options, { dispatch }) {
        try {
          const { data } = await axiosAuthService.post(`/api/auth/verify-email`, options);

          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return {
            error: error.response?.data?.message || error?.message || error,
          };
        }
      },
    }),
    addPassword: builder.mutation({
      async queryFn(options, { dispatch }) {
        try {
          const { password } = options;

          const { data } = await axiosAuthService.post(
            `/api/auth/set-password`,
            { password }
          );

          return { data: data || null };
        } catch (error) {
          return {
            error: error.response?.data?.message || error?.message || error,
          };
        }
      },
    }),

    forgotPassword: builder.mutation({
      async queryFn(email, { dispatch }) {
        try {
          const { data } = await axiosAuthService.post(
            `/api/auth/forgot-password`,
            {
              email,
            }
          );
          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
    }),

    signUpWithEmail: builder.mutation({
      async queryFn(email, { dispatch }) {
        try {
          const { data } = await axiosService.post(`${baseUrl}/app/account`, {
            email,
          });
          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
    }),

    userSignOut: builder.mutation({
      async queryFn(_, { dispatch }) {
        try {
          const { data } = await axiosAuthService.put(`/api/auth/logout`);
          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
    }),

    resendEmail: builder.mutation({
      async queryFn(email, { dispatch }) {
        try {
          const { data } = await axiosAuthService.post(
            `/api/auth/signup-link-resend`,
            {
              email,
            }
          );
          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
    }),

    addUserRoles: builder.mutation({
      async queryFn(roles, { dispatch }) {
        try {
          const { data } = await axiosService.patch(`${baseUrl}/app/user`, {
            ...roles,
          });

          dispatch(authActions.setUserIsVerify(data?.id));

          addCookie({ userId: data?.userId, roles: data.roles });

          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
    }),

    freeAccountByEmail: builder.mutation({
      async queryFn(payload, { dispatch }) {
        try {
          const { data } = await axiosService.post(`${baseUrl}/app/account`, {
            email: payload?.email,
          });
          localStorage.setItem("pageCount", "0");
          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
    }),

    getLoginByLinkCode: builder.query({
      queryFn: async (email, { dispatch }) => {
        try {
          const { data } = await axiosAuthService.get(
            '/api/auth/login-link',
            {
              params: { email },
            }
          );

          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
    }),

    loginByLink: builder.mutation({
      queryFn: async (code, { dispatch }) => {
        try {
          const { data } = await axiosService.post(
            `${baseUrl}/app/auth/login-link`,
            {
              code: code,
            }
          );

          dispatch(authActions.setUserIsVerify(data?.userId));
          addCookie({ userId: data?.userId, roles: data?.roles });
          localStorage.setItem("pageCount", "0");

          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return {
            error: error?.response?.data?.message || error?.message || error,
          };
        }
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useUserSignInMutation,
  useUserSignUpMutation,
  useGoogleAuthMutation,
  useLinkedInAuthMutation,
  useAddPasswordMutation,
  useForgotPasswordMutation,
  useSignUpWithEmailMutation,
  useVerifyEmailMutation,
  useUserSignOutMutation,
  useResendEmailMutation,
  useAddUserRolesMutation,
  useFreeAccountByEmailMutation,
  useLazyGetLoginByLinkCodeQuery,
  useLoginByLinkMutation,
} = authApi;

export default authApi;