import { Img } from "@chakra-ui/react";
import { memo, useEffect, useState } from "react";

const DefaultImage = ({ src, type, options }) => {
  const [imgSrc, setImgSrc] = useState("");

  const noImage =
    type === "person" ? "/images/no-avatar.svg" : "/images/no-company.svg";

  useEffect(() => {
    const checkImageExists = async (url) => {
      if (typeof window !== "undefined") {
        return new Promise((resolve) => {
          const img = new Image();
          img.onload = () => resolve(true);
          img.onerror = () => resolve(false);
          img.src = url;
        });
      } else {
        return false;
      }
    };

    if (!src) {
      setImgSrc(noImage);
      return;
    }

    checkImageExists(src).then((isImageExists) => {
      setImgSrc(isImageExists ? src : noImage);
    });

    return () => {};
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, noImage]);

  return <Img src={imgSrc} {...options} />;
};

export default memo(DefaultImage);
