import { HStack, VStack } from '@chakra-ui/react';
import { customTextSection } from '@/utils/cardCompanyResearch';
import SmallCard from '@/components/ui/SmallCard/SmallCard';
import { Fragment } from 'react';

const CARDS_PER_COLUMN = 2;
// const COLUMN_WIDTH = '308px';

function SwiperMobileCard({ cardsInfo, customCard }) {
  const columns = [];

  for (let i = 0; i < cardsInfo?.length; i += CARDS_PER_COLUMN) {
    const columnCards = cardsInfo.slice(i, i + CARDS_PER_COLUMN);
    columns.push(
      <VStack key={i} spacing='16px'>
        {columnCards.map((item) => (
          <Fragment key={item.id || item.nameBrand}>
            {!customCard ? (
              <SmallCard item={item} textSection={customTextSection} />
            ) : (
              customCard(item)
            )}
          </Fragment>
        ))}
      </VStack>
    );
  }

  return (
    <HStack
      minW='100%'
      maxW='380px'
      h='320px'
      overflow='auto'
      position='relative'
      mt='20px'
      px='24px'
      spacing='16px'
    >
      {columns}
    </HStack>
  );
}

export default SwiperMobileCard;
