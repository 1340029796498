import { useActions } from '@/hooks/useActions';
import { getError } from '@/store/error/error.selectors';
import { Flex, Text, useToast, Image } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const ErrorProvider = ({ children }) => {
  const errors = useSelector(getError());

  const toast = useToast();
  const { clearError } = useActions();

  useEffect(() => {
    if (errors) {
      errors?.forEach((error) => {
        toast({
          position: 'bottom',
          duration: 2500,
          containerStyle: { marginBottom: '60px' },
          render: (props) => {
            return (
              <Flex
                flexDir={'column'}
                rowGap={'16px'}
                minW={{ base: 'unset', md: '300px' }}
                maxW={'344px'}
                minH={'48px'}
                fontSize={'14px'}
                fontWeight={'600'}
                lineHeight={'120%'}
                color={!error?.includes('successfully') ? 'red' : 'white'}
                p={'16px 24px'}
                // bg={!error?.includes("successfully") ? "darkRedNew" : "#073325"}
                bg="#EDF5FF"
                flexDirection={'row'}
                gap="16px"
                alignItems="center"
                justifyContent="space-between"
                borderRadius="4px"
                boxShadow="0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)"
              >
                <Text
                  color="#22262A"
                  fontWeight={error?.includes('successfully') ? 400 : 500}
                >
                  {error}
                </Text>
                <Image
                  src="/images/close-round-light.svg"
                  cursor="pointer"
                  onClick={props.onClose}
                  alt=""
                />
              </Flex>
            );
          },
        });
      });
      clearError();
    }
  }, [clearError, errors, toast]);

  return children;
};

export default ErrorProvider;
