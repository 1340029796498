import { profileActions } from "@/store/profile/profile.slice";
import { aventureApi } from "./apiService/aventureApi";
import { axiosAuthService, axiosService } from "./apiService/axiosService";
import { loadingError } from "./error.service";

const profileApi = aventureApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserProfile: builder.query({
      async queryFn(_, { dispatch }) {
        try {
          const { data } = await axiosService.get("app/user");

          dispatch(profileActions.setProfile(data?.user));

          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
      providesTags: () => [{ type: "profile" }],
    }),

    getUserBillingPayment: builder.query({
      async queryFn(_, { dispatch }) {
        try {
          const { data } = await axiosService.get("app/invest/card");

          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
      providesTags: () => [{ type: "profile" }],
    }),

    addUserBillingPayment: builder.mutation({
      async queryFn(_, { dispatch }) {
        try {
          const { data } = await axiosService.post("app/invest/card");
          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
      invalidatesTags: [{ type: "profile" }],
    }),

    getUserBilling: builder.query({
      async queryFn(_, { dispatch }) {
        try {
          const { data } = await axiosService.get("app/user/payment");

          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
      providesTags: () => [{ type: "profile" }],
    }),

    getUserSubscription: builder.query({
      async queryFn(params, { dispatch }) {
        try {
          const { data } = await axiosService.get("app/user/subscription", {
            params: { ...params },
          });

          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
      providesTags: () => [{ type: "profile" }],
    }),

    updateUserInfo: builder.mutation({
      async queryFn(options, { dispatch }) {
        const { isBlurPopup, ...rest } = options;
        try {
          const { data } = await axiosService.patch("app/user/personal", {
            ...rest,
          });

          await axiosAuthService.patch("/api/auth/update-user", {
            ...rest,
          });

          !isBlurPopup &&
            dispatch(
              loadingError({ message: "Profile was saved successfully!" })
            );

          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
      invalidatesTags: [{ type: "profile" }],
    }),

    changePassword: builder.mutation({
      async queryFn(options, { dispatch }) {
        const { isBlurPopup, ...rest } = options;
        try {
          const { data } = await axiosAuthService.patch("/api/auth/update-user", {
            ...rest,
          }).catch(error => {
            if (error.response?.status === 400) {
              throw new Error("Current password is incorrect");
            } else if (error.response?.status === 429) {
              throw new Error("Too many attempts. Please try again later");
            }
            throw error;
          });
          !isBlurPopup &&
            dispatch(
              loadingError({ message: "Password changed successfully!" })
            );

          return { data };
        } catch (error) {
          console.log(error);
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
    }),

    updateSocialLink: builder.mutation({
      async queryFn(options, { dispatch }) {
        try {
          const { data } = await axiosService.patch("app/user/social", options);
          dispatch(
            loadingError({ message: "Social profiles are successfully saved!" })
          );

          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
      invalidatesTags: [{ type: "profile" }],
    }),

    addAvatar: builder.mutation({
      async queryFn(media, { dispatch }) {
        const formData = new FormData();
        formData.append("media", media);
        try {
          const { data } = await axiosService.put("app/user/avatar", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          dispatch(loadingError({ message: "Avatar changed successfully!" }));

          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
      invalidatesTags: [{ type: "profile" }],
    }),

    deleteAvatar: builder.mutation({
      async queryFn(_, { dispatch }) {
        try {
          const { data } = await axiosService.delete("app/user/avatar");

          dispatch(loadingError({ message: "Avatar deleted successfully!" }));

          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
      invalidatesTags: [{ type: "profile" }],
    }),

    disconnectSocial: builder.mutation({
      async queryFn(provider, { dispatch }) {
        try {
          const { data } = await axiosService.put(
            `app/user/${provider}-disconnect`
          );

          dispatch(loadingError({ message: "Disconnected successfully!" }));

          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
      invalidatesTags: [{ type: "profile" }],
    }),
    userReverseSupport: builder.mutation({
      async queryFn(options, { dispatch }) {
        try {
          const { data } = await axiosService.post("app/user/support", options);

          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
    }),

    investmentInAccFundStripe: builder.mutation({
      async queryFn(price, { dispatch }) {
        try {
          const { data } = await axiosService.post(
            `app/user/investment-payment`,
            {
              price,
            }
          );

          // dispatch(loadingError({ message: 'Error' }));

          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
      invalidatesTags: [{ type: "profile" }],
    }),

    generateQrCode: builder.query({
      async queryFn(_, { dispatch }) {
        try {
          const { data } = await axiosService.get(`app/auth/qr`);

          return { data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
    }),

    toggle2faCode: builder.mutation({
      async queryFn(authCode) {
        try {
          const { data } = await axiosService.post(
            "/app/auth/turn-on-2fa",
            authCode
          );

          return { data };
        } catch (error) {
          return { error: error?.message };
        }
      },
      invalidatesTags: [{ type: "profile" }],
    }),
    getPricing: builder.query({
      async queryFn(params, { dispatch }) {
        try {
          const { data } = await axiosService.get("app/payment/prices", {
            params: { ...params },
          });

          return { data: data?.data };
        } catch (error) {
          dispatch(loadingError(error));
          return { error: error?.message || error };
        }
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetUserProfileQuery,
  useLazyGetUserBillingQuery,
  useLazyGetUserBillingPaymentQuery,
  useAddUserBillingPaymentMutation,
  useLazyGetUserSubscriptionQuery,
  useUpdateUserInfoMutation,
  useChangePasswordMutation,
  useUpdateSocialLinkMutation,
  useAddAvatarMutation,
  useDeleteAvatarMutation,
  useDisconnectSocialMutation,
  useUserReverseSupportMutation,
  useInvestmentInAccFundStripeMutation,
  useGenerateQrCodeQuery,
  useToggle2faCodeMutation,
  useLazyGetPricingQuery,
} = profileApi;

export default profileApi;
