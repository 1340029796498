import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";
// import CheckIcon from "@/assets/svg/check-icon.svg";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  label: {
    fontSize: "0.875rem",
    fontWeight: "400",
    lineHeight: "1.35",
    fontStyle: "normal",
    letterSpacing: "0.28px",
  },
  icon: {
    w: "14.25px",
    height: "14.25px",
    color: "mainBlue",
  },
  control: {
    borderWidth: "0.75px",
    borderRadius: "3.75px",
    "&[data-checked]": {
      borderColor: "mainBlue",
    },
    "&[data-hover]": {
      borderColor: "black",
    },
    borderColor: "mainBlue",
    w: "14.25px",
    height: "14.25px",
    svg: {
      w: "10px",
    },
  },
});

const table = definePartsStyle({
  label: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "135%",
    fontStyle: "normal",
    letterSpacing: "0.28px",
  },
  icon: {
    width: "16px",
    height: "16px",
    color: "mainBlue",
  },
  control: {
    borderWidth: "1px",
    borderRadius: "3px",
    "&[data-checked]": {
      borderColor: "grey",
    },
    "&[data-hover]": {
      borderColor: "black",
    },
    "&[data-focus]": {
      boxShadow: "none",
      outline: "none",
    },
    borderColor: "grey",
    boxShadow: "none",
    width: "16px",
    height: "16px",
    svg: {
      width: "12px",
    },
  },
});

export const customCheckboxTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { table },
});
