import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AnyAction } from "@reduxjs/toolkit";

export const aventureApi = createApi({
  reducerPath: "aventureApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API_URL || "https://api.aventure.vc/api",
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      // Add any authorization headers if needed
      return headers;
    },
  }),
  tagTypes: [
    "search",
    "companies",
    "funds",
    "fundraising",
    "people",
    "investors",
    "fundPersons",
    "fundInvestment",
    "tags",
    "profile",
    "filters",
    "notifications-settings",
    "notifications",
    "admin",
    "views",
    "listByView",
    "lists",
    "newViews",
    "companiesList",
  ],
  endpoints: () => ({}),
});
