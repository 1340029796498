import { BlockWithBlueArrowTitle } from '@/components/ui/box/BlockWithBlueArrowTitle';
import { CustomElement } from '@/components/ui/box/CustomElement';
import { Flex, useMediaQuery } from '@chakra-ui/react';
import Link from 'next/link';

export const PopularResearchTags = ({ tagsList }) => {
  const [isMobile] = useMediaQuery('(max-width: 767px)');
  const [isTablet] = useMediaQuery('(max-width: 1050px)');

  const createTagsList = () => {
    if (isMobile) {
      return tagsList?.slice(0, 35);
    }
    if (isTablet) {
      return tagsList?.slice(0, 20);
    }
    return tagsList?.slice(0, 35);
  };

  return (
    <BlockWithBlueArrowTitle
      title={'Popular Research Tags'}
      customStyleOuterBox={{
        flex: '1',
        h: { base: '100%', md: '100%' },
      }}
    >
      <Flex flexWrap={'wrap'} justifyContent={'center'} gap={'12px'}>
        {tagsList
          ? createTagsList()?.map((el) => {
              return (
                <PopularResearchTagElement
                  key={'popularTag' + el?.slug}
                  {...{ data: el }}
                />
              );
            })
          : null}
      </Flex>
    </BlockWithBlueArrowTitle>
  );
};

function PopularResearchTagElement({ data }) {
  return (
    <Link href={`/companies/tags/${data?.slug}`}>
      <CustomElement
        {...{
          variant: 'whiteBtnOutline',
          title: data?.name,
          // handler,
          customStyle: { p: '12px 20px 10px 20px' },
        }}
      ></CustomElement>
    </Link>
  );
}
