"use client";

import Link from "next/link";
import { Flex, Text } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import { useEffect, useState } from "react";
import env_config from "env_config";
import DefaultImage from "@/components/ui/DefaultImage";

const NewsItem = ({ data }) => {
  const imgStyle = {
    w: "80px",
    h: "80px",
    borderRadius: "4px",
    overflow: "hidden",
    minWidth: "80px",
    minHeight: "80px",
    maxWidth: "80px",
    maxHeight: "80px",
    objectFit: "scale-down",
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      setCountSlides(Math.floor(((window.innerWidth + 20) / 380) * 10) / 10);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setCountSlides(Math.floor(((window.innerWidth + 20) / 380) * 10) / 10);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const imgUrl = env_config.apiNewsImageHostName;

  const [countSlides, setCountSlides] = useState(
    typeof window !== "undefined"
      ? Math.floor(Math.floor(((window.innerWidth + 20) / 380) * 10) / 10)
      : 3
  );

  const [beforeAtlas, setBeforeAtlas] = useState(false);

  useEffect(() => {
    setCountSlides(Math.floor(((window.innerWidth + 20) / 380) * 10) / 10);
    setBeforeAtlas(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!beforeAtlas]);

  return (
    <>
      {countSlides ? (
        <Swiper
          className="swiperHomeTop"
          slidesPerView={countSlides}
          spaceBetween={20}
          modules={[Pagination]}
          loop="true"
        >
          {data?.map((item, index) => {
            const imageSrc = item?.newsImageThumbnail
              ? `${imgUrl}${item?.newsImageThumbnail}`
              : "/images/no-company.svg";
            return (
              <SwiperSlide
                key={"slider" + index}
                style={{ backgroundColor: "transparent" }}
              >
                <Link href={`/news/${item?.slug}`} alt={item?.title}>
                  <li>
                    <Flex
                      p={"20px"}
                      cursor={"pointer"}
                      alignItems={"start"}
                      columnGap={"12px"}
                      h={"140px"}
                      w={"360px"}
                      borderRadius={"7.5px"}
                      bg={"white"}
                      borderWidth={"0.75px"}
                      borderColor={"menuGray"}
                    >
                      <DefaultImage
                        src={imageSrc}
                        options={{
                          alt: "news image",
                          sx: imgStyle,
                          loading: "lazy",
                        }}
                      />
                      <Flex
                        flexDir={"column"}
                        rowGap={"4px"}
                        textAlign={"left"}
                      >
                        <Text
                          fontSize={"14px"}
                          fontWeight={"700"}
                          letterSpacing={"0.28px"}
                          lineHeight={"1.2"}
                          noOfLines={2}
                        >
                          {item?.title}
                        </Text>
                        <Text
                          fontSize={"14px"}
                          fontWeight={"400"}
                          letterSpacing={"0.48px"}
                          lineHeight={"1.35"}
                          noOfLines={2}
                        >
                          {item?.description}
                        </Text>
                        <Text
                          fontSize={"14px"}
                          fontWeight={"700"}
                          letterSpacing={"0.28px"}
                          lineHeight={"1.2"}
                        >
                          {new Date(item?.publishedDate).toLocaleDateString(
                            "en-eu",
                            {
                              day: "numeric",
                              month: "short",
                              year: "numeric",
                            }
                          )}
                        </Text>
                      </Flex>
                    </Flex>
                  </li>
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      ) : null}
    </>
  );
};

export default NewsItem;
