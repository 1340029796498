import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENVIRONMENT_CONFIGS } from "env_config";
import qs from "qs";
import { axiosServiceStrapi } from "./apiService/axiosServiceStrapi";

interface StrapiResponse<T = any> {
  data: T;
  meta?: Record<string, any>;
}

const articlesPopulate = {
  0: "categories",
  1: "author",
  2: "author.image",
  3: "seo.metaImage",
};

export const strapiApi = createApi({
  reducerPath: "strapi",
  tagTypes: ['strapi'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENVIRONMENT_CONFIGS.strapi.url}/api`,
    prepareHeaders: (headers) => {
      headers.set(
        "Authorization",
        `Bearer ${ENVIRONMENT_CONFIGS.strapi.apiToken}`
      );
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPrices: builder.query<any, void>({
      query: () => ({
        method: "GET",
        url: "/price",
        params: {
          populate: ["priceData.advantages"],
        },
      }),
      transformResponse: (response: StrapiResponse) => response?.data,
    }),
    getMembers: builder.query<any, void>({
      query: () => ({
        method: "GET",
        url: "/member",
        params: {
          populate: ["images"],
        },
      }),
      transformResponse: (response: StrapiResponse) => response?.data,
    }),
    getArticles: builder.query<any, { id?: string; filters?: any; pagination?: any }>({
      async queryFn({ id, filters, pagination }) {
        try {
          const query = {
            populate: articlesPopulate,
            filters,
            pagination,
          };
          const { data } = await axiosServiceStrapi.get(
            `/articles${id ? `/${id}` : ""}`,
            {
              params: { ...query },
              paramsSerializer: (params) =>
                qs.stringify(params, { encodeValuesOnly: true }),
            }
          );
          return { data: data?.data };
        } catch (error: any) {
          return { error: error?.message || error };
        }
      },
    }),
    getOneArticle: builder.query<any, string>({
      query: (id) => ({
        method: "GET",
        url: `/articles/${id}`,
        params: {
          populate: articlesPopulate,
        },
      }),
      transformResponse: (response: StrapiResponse) => response?.data,
    }),
    getUsers: builder.query<any, void>({
      query: () => ({
        method: "GET",
        url: "/users",
      }),
      transformResponse: (response: StrapiResponse) => response?.data,
    }),
    getAuthor: builder.query<any, string>({
      query: (id) => ({
        method: "GET",
        url: `/authors/${id}`,
        params: {
          populate: ["image"],
        },
      }),
      transformResponse: (response: StrapiResponse) => response?.data,
    }),
    getCategories: builder.query<any, void>({
      query: () => ({
        method: "GET",
        url: "/categories",
      }),
      transformResponse: (response: StrapiResponse) => response?.data,
    }),
    getCategory: builder.query<any, string>({
      query: (id) => ({
        method: "GET",
        url: `/categories/${id}`,
      }),
      transformResponse: (response: StrapiResponse) => response?.data,
    }),
    getStaticPage: builder.query<any, string>({
      query: (id) => ({
        method: "GET",
        url: `/static-pages/${id}`,
        params: {
          populate: ["seo.metaImage"],
        },
      }),
      transformResponse: (response: StrapiResponse) => response?.data,
    }),
  }),
});

export const {
  useGetPricesQuery,
  useGetMembersQuery,
  useGetArticlesQuery,
  useLazyGetArticlesQuery,
  useGetOneArticleQuery,
  useGetUsersQuery,
  useGetAuthorQuery,
  useGetCategoriesQuery,
  useGetCategoryQuery,
  useGetStaticPageQuery,
} = strapiApi;

export async function getStaticPageData(slug: string) {
  const baseUrl = process.env.NEXT_PUBLIC_STRAPI_URL || 'https://strapi.aventure.vc/api';
  const response = await fetch(`${baseUrl}/${slug}`, {
    next: { revalidate: 3600 }, // Cache for 1 hour
  });
  
  if (!response.ok) {
    console.error(`Failed to fetch static page data for ${slug}`);
    return null;
  }
  
  return response.json();
}
