import {
  addToLocalStorage,
  getFromLocalStorage,
  removeFromLocalStorage,
} from "@/services/localStorage.service";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserSignUpInfo {
  // Add user signup info fields here
  [key: string]: any;
}

interface AuthState {
  userIsVerify: string | null;
  userSignUpInfo: UserSignUpInfo | null;
  userSignUpEmail: string;
  isNeed2fA: string;
  isGoogle2fA: string;
  isLinkedIn2fA: string;
}

const initialState: AuthState = {
  userIsVerify: getFromLocalStorage("userVerify") || null,
  userSignUpInfo: null,
  userSignUpEmail: "",
  isNeed2fA: "",
  isGoogle2fA: "",
  isLinkedIn2fA: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserIsVerify: (state, { payload }: PayloadAction<string | null>) => {
      payload && addToLocalStorage("userVerify", payload);
      const verify = payload && getFromLocalStorage("userVerify");
      state.userIsVerify = verify;
    },
    setUserSignUpInfo: (state, { payload }: PayloadAction<UserSignUpInfo | null>) => {
      state.userSignUpInfo = payload;
    },
    setUserSignUpEmail: (state, { payload }: PayloadAction<string>) => {
      state.userSignUpEmail = payload;
    },
    setIsNeed2FA: (state, { payload }: PayloadAction<string>) => {
      state.isNeed2fA = payload;
    },
    setIsGoogle2FA: (state, { payload }: PayloadAction<string>) => {
      state.isGoogle2fA = payload;
    },
    setIsLinkedIn2FA: (state, { payload }: PayloadAction<string>) => {
      state.isLinkedIn2fA = payload;
    },
    userLogOut: (state) => {
      document.cookie.split(";").forEach((cookie) => {
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
        document.cookie =
          name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      });
      removeFromLocalStorage("userVerify");
      state.userIsVerify = null;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice;
