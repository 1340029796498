import { h_700_16_100_32 } from "@/styles/fontStyles";
import { Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { RunningImageHandler } from "./RunningImageHandler";

export const RunningImageString = ({ currentCompaniesList }) => {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    if (!currentCompaniesList?.length) return;
    
    // Just filter for companies with logos and limit to 40
    const filtered = currentCompaniesList
      ?.filter(item => item.fileLogoSquare)
      ?.slice(0, 40);
      
    setCompanies(filtered);
  }, [currentCompaniesList]);

  return (
    <Flex
      flexDir={"column"}
      align={"center"}
      gap={"24px"}
      mb={{ base: "42px", md: "14px", lg: "120px" }}
    >
      <Text
        {...h_700_16_100_32}
        color={"mainBlue"}
        px={"20px"}
        textAlign={"center"}
      >
        Research early, fast growing startups like these with aVenture
      </Text>
      <RunningImageHandler data={companies?.slice(0, 20)} />
      <RunningImageHandler runDir={"left"} data={companies?.slice(20, 40)} />
    </Flex>
  );
};
