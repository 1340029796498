'use client';

import { h_700_14_100_28 } from '@/styles/fontStyles';
import { Button, ButtonProps, Text } from '@chakra-ui/react';
import { memo, ReactNode, RefObject, ReactElement, JSXElementConstructor } from 'react';

interface DefaultBtnProps extends Omit<ButtonProps, 'onClick'> {
  isLoading?: boolean;
  children?: ReactNode;
  title?: string;
  leftIcon?: ReactElement<any, string | JSXElementConstructor<any>>;
  rightIcon?: ReactElement<any, string | JSXElementConstructor<any>>;
  customStyles?: Record<string, any>;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  btnRef?: RefObject<HTMLButtonElement>;
  error?: boolean;
  id?: string;
  backgroundColor?: string;
  borderColor?: string;
  color?: string;
  hoverBgColor?: string;
  hoverColor?: string;
  hoverBorderColor?: string;
  textStyles?: Record<string, any>;
}

export const ButtonDefault = memo(function ButtonDefault({
  isLoading,
  children,
  title,
  leftIcon,
  rightIcon,
  customStyles,
  onClick,
  type,
  btnRef,
  error = false,
  id,
  backgroundColor = 'white',
  borderColor = 'mainBlue',
  color = 'white',
  hoverBgColor,
  hoverColor,
  hoverBorderColor,
  textStyles,
  ...props
}: DefaultBtnProps) {
  return (
    <Button
      position={'relative'}
      overflow={'hidden'}
      borderRadius={'8px'}
      border={'1px solid'}
      borderColor={error ? 'error' : borderColor}
      bg={error ? 'error' : backgroundColor}
      color={error ? 'white' : color}
      onClick={onClick}
      type={type}
      _hover={{
        bg: error ? 'error' : hoverBgColor ?? backgroundColor,
        color: error ? 'white' : hoverColor ?? color,
        borderColor: error ? 'error' : hoverBorderColor ?? borderColor,
      }}
      isDisabled={error}
      ref={btnRef}
      {...{ isLoading }}
      {...customStyles}
      {...(id && { id })}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      {...props}
    >
      {children ? (
        children
      ) : (
        <Text {...h_700_14_100_28} {...textStyles}>
          {title}
        </Text>
      )}
    </Button>
  );
});

ButtonDefault.displayName = 'ButtonDefault';

export default ButtonDefault;
